import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useJob } from '../components/JobProvider';
import DashboardNav from '../components/DashboardNav';
import styles from './MyTeam.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { formatTeam, formatSpyCareTeam } from '../utils';
import { usePortal } from '../components/PortalProvider';
import { useAuth } from '../components/AuthProvider';

export default function KickoffMeeting() {
  const { jobId: job, spycareJobId: spycareJob } = useJob()
  const [team, setTeam] = useState([])
  const portal = usePortal();
  const { token } = useAuth()

  useEffect(() => {
    if (portal === 'SpyGlass') {
      if (job) {
        fetch(`${process.env.REACT_APP_API_URL}/data/job/${job}/team`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(team => {
            setTeam(formatTeam(team))
          })
      }
    } else {
      if (spycareJob) {
        fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/${spycareJob}/team`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => res.json())
          .then(team => {
            setTeam(formatSpyCareTeam(team).filter(m => ['Outside Sales Rep', 'Account Manager'].includes(m.roleName)))
          })
      }
    }
  }, [job, spycareJob, portal])


  return (
    <>
      <DashboardNav />
      <Container className="mt-3">
        <Row>
          <Col xs={12} className="d-flex justify-content-between align-content-center">
            <h1 className="mb-0">My Team</h1>
          </Col>
        </Row>
      </Container>
      <Container className="mb-3">
        <Row>
          {/* REPEATER START  - This is set up for minimal information, if we get a bio for team members change Col to 6 Card > Col to 4 + 8*/}
          {team.map(member => (
            <Col xs={12} lg={6} xl={4}>
              <Card className="mt-3 card-h-100-calc">
                <Card.Body className="d-sm-flex d-block">
                  <Col xs={12} sm={6}>
                    <div className={styles.pfp}>
                      <div className={styles.teamMemberPhoto} style={member.url ? { backgroundImage: `url(${member.url})` } : {}}></div>
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className="cardTitleContainer">
                      <Card.Title className="mb-1">{member.firstName} {member.lastName}</Card.Title>
                    </div>
                    <h6>{member.title}</h6>
                    <Card.Text>
                      {member.phone} <br />
                      <a href={`mailto:${member.email}`}>{member.email}</a><br />
                    </Card.Text>
                  </Col>
                </Card.Body>
                <Card.Footer><a href={`mailto:${member.email}`} className="card-link">Send Email <FontAwesomeIcon icon="envelope" /></a></Card.Footer>
              </Card>
            </Col>
          ))}
          {/* REPEATER END */}
        </Row>
      </Container>
    </>
  )
}
