import styles from './Login.module.scss'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function generateVerifier() {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  const array = new Uint8Array(128)
  window.crypto.getRandomValues(array)

  const verifier = []
  array.forEach((x) => verifier.push(validChars[x % validChars.length]))

  return verifier.join('')
}

function base64ToURL(s) {
  return s
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

function generateLoginRequest() {
  const state = base64ToURL(btoa(generateVerifier()))

  sessionStorage.setItem('state', state)
  window.location.href = `${process.env.REACT_APP_API_URL}/oauth/authorize?response_type=token&client_id=portal&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}/&scope=read&state=${state}`
}

const Login = () => (
  <>
    <div className={styles.loginWrapper}>
      <Container>
        <Row className="text-center justify-content-center">
          <Col xs={12} sm={4}>
            <div className={styles.loginCenter}>
              <div className={styles.loginLogo}>
                <img src="/logo/SpyGlass.svg" alt="SpyGlass" />
              </div>
              <Button variant="secondary" size="lg" className="mt-3" onClick={generateLoginRequest}>
                Login
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </>
)

export default Login
