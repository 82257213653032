import React from 'react';
import styles from './ResourcesDataCell.module.scss'
import { Link } from 'react-router-dom';

const ResourcesDataCell = (props) => {
    console.log(props);

    return(
        <div className={styles.resourceRow} key={props.data.documentId}>
        <div className={styles.resource}>
          <div>
            {props.data.filetype && props.data.filetype.includes('pdf')
              ? (<a href={props.data.url} rel="noreferrer noopener" target="_blank">{props.data.documentName}</a>)
              : !props.data.base64
                ? props.data.fileName.charAt(0) !== '/'
                  ? (<a href={props.data.fileName} rel="noreferrer noopener" target="_blank">{props.data.documentName}</a>)
                  : <Link to={props.data.fileName}>{props.data.documentName}</Link>
                : (<Link to={props.data.fileName}>{props.data.documentName}</Link>)
            }
          </div>
          <div className={styles[props.data.filetype && props.data.filetype.includes('pdf') ? 'PDF' : 'LINK']}>
            {props.data.filetype && props.data.filetype.includes('pdf') ? 'PDF' : 'LINK'}
          </div>
        </div>
        <hr />
      </div>
    )
}

export default ResourcesDataCell