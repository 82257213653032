import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import styles from './ContractTrackerProgressBar.module.scss';

const ContractTrackerProgressBar = (props) => {

    const value = props.value;

    const getVariant = (val) => {
        if (val.percent === 0) {
            return 'grey'
        }
        else if (val.daysLeft >= 181) {
            return 'success';
        } else if (val.daysLeft > 90 && val.daysLeft <= 180) {
            return 'warning';
        } else {
            return 'error'
        }
    }

    return (
            <div>
                { value !== null
                    ?
                        <ProgressBar className={styles.progressBar} variant={getVariant(value)} now={value.percent} label={`${value.percent}%`}/>
                    :   
                        null
                }
            </div>
        );
}

export default ContractTrackerProgressBar