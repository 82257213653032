import React, { useState, useMemo, useEffect } from 'react';
import ContractTrackerDetailCell from '../components/ContractTrackerDetailCell';
import ContractTrackerProgressBar from '../components/ContractTrackerProgressBar';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import contractList from '../json/contracts.json';
import styles from './ContractTracker.module.scss';
import { Redirect } from 'react-router';
import { Tabs, Tab, Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { useJob } from '../components/JobProvider';
import { usePortal } from '../components/PortalProvider';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DashboardNav from '../components/DashboardNav';
import { formatAsCurrency } from '../utils';
import moment from 'moment';

const ContractTracker = () => {
  const [contracts, setContracts] = useState([]);
  const [contractTotalSummary, setContractTotalSummary] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [selectedType, setSelectedType] = useState("All")
  const [columnDefs] = useState([
    { field: "topContract", cellRenderer: "agGroupCellRenderer", cellClass: "topContract" },
    { field: "provider" },
    { field: "accountNumber" },
    {
      field: "termCompleted",
      sortable: false,
      cellRendererFramework: ContractTrackerProgressBar,
      cellStyle: {
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': 'center'
      },
    },
    { field: "term" },
    { field: "expirationDate" },
    { field: "daysRemaining", sort: 'asc' },
    { field: "autoRenew" }
  ]);
  const { spycareJobId: spycareJob } = useJob()


  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
  }, []);

  const token = '5dc2161a-b9e3-49fe-809a-d8d01c831fff';

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/contract/spycare_job/${spycareJob}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => res.json())
      .then(resContracts => {
        const displayContracts = resContracts.map((contract) => {
          const parsedTerm = contract?.term?.replace(/[^0-9]/g, '') || 0;

          const autoRenewDisplay = () => {
            switch (contract.autoRenew) {
              case 0:
                return 'No';
              case 1:
                return 'Yes';
              case -1:
              default:
                return 'Not Specified';
            }
          }
          return {
            topContract: contract.topContract === 1 ? "*" : "",
            provider: contract.provider,
            accountNumber: contract.accountNumber,
            term: contract.term ? contract.term : '',
            termCompleted: parsedTerm ? calculateDatePercentage(contract.expirationDate, parsedTerm) : null,
            expirationDate: contract.expirationDate != null ? new Date(contract.expirationDate.replace(/-/g, '\/')).toLocaleDateString() : null,
            daysRemaining: contract.expirationDate != null ? Math.max(0, moment(contract.expirationDate).diff(moment(), 'days')) : null,
            autoRenew: autoRenewDisplay(),
            notes: contract.actionComment,
            serviceCategory: contract.servicesIncluded,
            monthlyCommitment: contract.commitments,
            location: contract.location,
            additionalLocation: contract.additionalLocation
          }
        })
        setContracts(displayContracts);

        const sum = (collection, prop) => {
          return collection.reduce((a, b) => a + b[prop], 0);
        };

        const contractSums = {
          total: displayContracts.length,
          expired: displayContracts.filter((c) => {
            const expiration = new Date(c.expirationDate);
            const today = new Date();

            return expiration <= today
          }).length,
          expiringIn30Days: displayContracts.filter((c) => {
            const expiration = new Date(c.expirationDate);
            const today = new Date();
            const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));

            return expiration > new Date() && expiration <= thirtyDaysFromNow;
          }).length,
          expiringIn60Days: displayContracts.filter((c) => {
            const expiration = new Date(c.expirationDate);
            let today = new Date();
            const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
            today = new Date();
            const sixtyDaysFromNow = new Date(today.setDate(today.getDate() + 60));

            return expiration > new Date() && expiration >= thirtyDaysFromNow && expiration <= sixtyDaysFromNow;
          }).length,
          expiringIn90Days: displayContracts.filter((c) => {
            const expiration = new Date(c.expirationDate);
            let today = new Date();
            const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
            today = new Date();
            const sixtyDaysFromNow = new Date(today.setDate(today.getDate() + 60));
            today = new Date();
            const ninetyDaysFromNow = new Date(today.setDate(today.getDate() + 90));

            return expiration > new Date() && expiration >= thirtyDaysFromNow && expiration >= sixtyDaysFromNow && expiration <= ninetyDaysFromNow;
          }).length,
          topContracts: sum(resContracts, 'topContract'),
          monthToMonth: displayContracts.filter((c) => c.expirationDate === null).length
        };

        setContractTotalSummary(contractSums);
      });
  }, [spycareJob]);

  const calculateDatePercentage = (endString, term) => {
    const end = moment(endString);
    const start = moment(end).subtract(term, 'months')
    let p = Math.round((moment().diff(start, 'days')) / (end.diff(start, 'days')) * 100)
    p = p > 100 ? p = 100 : p;
    const daysLeft = end.diff(moment(), 'days')
    return {
      percent: p,
      daysLeft: daysLeft
    };
  }

  const generateRowData = () => {
    switch (selectedType) {
      case 'All':
        return contracts;
      case 'Expired':
        return contracts.filter((c) => {
          const expiration = new Date(c.expirationDate);
          const today = new Date();

          return expiration <= today;
        })
      case 'Expiring in 30 Days':
        return contracts.filter((c) => {
          const expiration = new Date(c.expirationDate);
          const today = new Date();
          const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));

          return expiration > new Date() && expiration <= thirtyDaysFromNow;
        });
      case 'Expiring in 60 Days':
        return contracts.filter((c) => {
          const expiration = new Date(c.expirationDate);
          let today = new Date();
          const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
          today = new Date();
          const sixtyDaysFromNow = new Date(today.setDate(today.getDate() + 60));

          return expiration > new Date() && expiration >= thirtyDaysFromNow && expiration <= sixtyDaysFromNow;
        });
      case 'Expiring in 90 Days':
        return contracts.filter((c) => {
          const expiration = new Date(c.expirationDate);
          let today = new Date();
          const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
          today = new Date();
          const sixtyDaysFromNow = new Date(today.setDate(today.getDate() + 60));
          today = new Date();
          const ninetyDaysFromNow = new Date(today.setDate(today.getDate() + 90));

          return expiration > new Date() && expiration >= thirtyDaysFromNow && expiration >= sixtyDaysFromNow && expiration <= ninetyDaysFromNow;
        })
      case 'Top Contracts':
        return contracts.filter((c) => c.topContract);
      case 'Month to Month':
        return contracts.filter((c) => c.expirationDate === null);
      default:
        console.log('An error has occurred');
    }
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
  }

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  }
  return (
    <>
      <DashboardNav />
      <Container className="mt-3">
        <Row>
          <Col className='mb-0'>
            <h1>Contract Management</h1>
          </Col>
        </Row>
        <Row className=''>
          <Col xs={12}>
            <Card className='card-h-100'>
              <Card.Body className=''>
                <Row>
                  <Col className="mb-3">
                    <Tab.Container id="list-group-tabs" activeKey={selectedType}>
                      <ListGroup horizontal className={styles.contractSummary}>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="All"
                          action
                          onClick={() => {
                            setSelectedType('All');
                          }}
                        >
                          <div>All</div>
                          <div className={styles.summaryValue}>{contractTotalSummary?.total}</div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="Expired"
                          action
                          onClick={() => {
                            setSelectedType('Expired');
                          }}
                        >
                          <div>Expired</div>
                          <div className={`${styles.summaryValue} ${selectedType !== 'Expired' ? styles.expired : ''}`}>{contractTotalSummary?.expired}</div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="Expiring in 30 Days"
                          action
                          onClick={() => {
                            setSelectedType('Expiring in 30 Days');
                          }}
                        >
                          <div>Expiring in 30 Days</div>
                          <div className={`${styles.summaryValue} ${selectedType !== 'Expiring in 30 Days' ? styles.expire30Days : ''}`}>
                            {contractTotalSummary?.expiringIn30Days}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="Expiring in 60 Days"
                          action
                          onClick={() => {
                            setSelectedType('Expiring in 60 Days');
                          }}
                        >
                          <div>Expiring in 60 Days</div>
                          <div className={`${styles.summaryValue} ${selectedType !== 'Expiring in 60 Days' ? styles.expire60Days : ''}`}>
                            {contractTotalSummary?.expiringIn60Days}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="Expiring in 90 Days"
                          action
                          onClick={() => {
                            setSelectedType('Expiring in 90 Days');
                          }}
                        >
                          <div>Expiring in 90 Days</div>
                          <div className={styles.summaryValue}>{contractTotalSummary?.expiringIn90Days}</div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="Top Contracts"
                          action
                          onClick={() => {
                            setSelectedType('Top Contracts');
                          }}
                        >
                          <div>Top Contracts</div>
                          <div className={`${styles.summaryValue} ${selectedType !== 'Top Contracts' ? styles.topContracts : ''}`}>
                            {contractTotalSummary?.topContracts}
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="Month to Month"
                          action
                          onClick={() => {
                            setSelectedType('Month to Month');
                          }}
                        >
                          <div>Month to Month</div>
                          <div className={styles.summaryValue}>
                            {contractTotalSummary?.monthToMonth}
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </Tab.Container>
                  </Col>
                </Row>
                <div className='table-container contract-tracker-grid' style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: 'calc(100vh - 102px - 3rem - 1.5rem - 1rem - 63px - 53px - 70px)',
                      width: '100vw'
                    }}>
                    <AgGridReact
                      masterDetail={true}
                      enableRangeSelection
                      detailCellRenderer={'detailCell'}
                      frameworkComponents={{ detailCell: ContractTrackerDetailCell }}
                      onGridReady={onGridReady}
                      onFirstDataRendered={onFirstDataRendered}
                      detailRowAutoHeight={true}
                      rowData={generateRowData()}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      >
                    </AgGridReact>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ContractTracker;
