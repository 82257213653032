import React from 'react';
import styles from './FileView.module.scss';

export default function FileView(props) {
    const fileSizeInMB = (props.file.size / (1024*1024)).toFixed(2);
    const fileName = props.file.name;
    return (
        <div className={styles.fileView}>
            <div className={styles.fileSize}>{fileSizeInMB}MB</div>
            <div className={styles.fileName}>{fileName}</div>
        </div>
    );
}