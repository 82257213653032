import React, { useState } from 'react';
import Tab from './Tab';
import styles from './Tabs.module.scss';

const Tabs = (props) => {
    const [activeTab, setActiveTab] = useState(props.children[0].props.label);

    const onClickTabItem = (tab) => {
        setActiveTab(tab);
    }

    return (
        <div className={styles.tabs}>
            <ol className={styles.tabList}>
                {props.children.map((child) => {
                if (child === null) return null;
                const { label } = child.props;
                
                return (
                  <Tab
                    activeTab={activeTab}
                    key={label}
                    label={label}
                    onClick={onClickTabItem}
                  />
                );
            })}
            </ol>
            <div className={styles.tabContent}>
                {props.children.map((child) => {
                    if (child === null) return null;
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
}

export default Tabs;