import React, { useState } from 'react';
import styles from './FileUploader.module.scss';
import FileView from './FileView';

export default function FileUploader(props) {
  const [uploadFiles, setUploadFiles] = useState([]);

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    setUploadFiles([...uploadFiles, ...e.dataTransfer.files]);

    console.log("Files dropped: ", e.dataTransfer.files);
  };

  const handleUploadClick = e => {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData();
    formData.append('files', uploadFiles);

    props.callback(uploadFiles);
    setUploadFiles([]);
  }

  return (
    <div className={`${styles.fileUploaderContainer} ${uploadFiles.length > 0 ? styles.uploadView : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnter={handleDragEnter}>
      {uploadFiles.length > 0
        ? <>
          <div className={styles.fileViewContainer}>
            {uploadFiles.map((file, index) =>
              <FileView file={file} key={index} />
            )}
          </div>
          <button className={styles.uploadButton} onClick={handleUploadClick}>Upload Files</button>
        </>
        : null
      }
      <>
        <input type="file" multiple name="file" id="file" onChange={(e) => { setUploadFiles([...uploadFiles, ...e.target.files]); }} />
        <label htmlFor="file">Choose File</label>
        <p>or drag file in here</p>
      </>
    </div>
  );
}