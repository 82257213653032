import React from 'react';
import styles from './ContractTrackerDetailCell.module.scss';

const ContractTrackerDetailCell = ({ data }) => {
    console.log(data)
    return (
  <div className={styles.cellDetailPanel}>
      <div className={styles.cellDetails}>
          <div className={styles.cellDetail}>
              <b>Monthly Commitment: </b>
              {data.monthlyCommitment}
          </div>
          <div className={styles.cellDetail}>
              <b>Service Category: </b>
              {data.serviceCategory}
          </div>
          <div className={styles.cellDetail}>
              <b>Notes: </b>
              {data.notes}
          </div>
          <div className={styles.cellDetail}>
              <b>Location: </b>
              {data.location}
          </div>
          <div className={styles.cellDetail}>
              <b>Additional Location: </b>
              {data.additionalLocation}
          </div>
      </div>
  </div>
);
    }

export default ContractTrackerDetailCell;