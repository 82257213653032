import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import recColumns from '../json/recommendation_columns.json';
import recTypes from '../json/recommendation_types.json';
import { formatters, getters, renderers, feedbackCellClassRules } from '../recRendering';
import DashboardNav from '../components/DashboardNav';
import GridModal from '../components/GridModal';
import EditGridModal from '../components/EditGridModal';
import { deleteGrid, formatAsCurrency, mapTypeToId, formatHealthCheckAsJob } from '../utils';
import { calculateSavings } from '../utils/calculate';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import { useJob } from '../components/JobProvider';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import styles from './Recommendations.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";
import { useSavings } from '../components/SavingsProvider';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useAuth } from '../components/AuthProvider';

const HealthCheckRecommendations = () => {
  const { spycareJobId: spycareJob } = useJob()
  const { healthCheckId } = useParams()
  const { setNavSavings } = useSavings()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const gridId = searchParams.get('gridId')
  const defaultType = searchParams.get('type')
  const recType = searchParams.get('recType')
  const gridRef = useRef(null)
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)
  const [gridData, setGridData] = useState(null)
  const [gridColumns, setGridColumns] = useState(null)
  const [initialGridFilters, setInitialGridFilters] = useState(null)
  const [gridConfigs, setGridConfigs] = useState([])
  const [gridConfig, setGridConfig] = useState(null)
  const [jobData, setJobData] = useState({})
  const [feedbackTypes, setFeedbackTypes] = useState([])
  const [reasons, setReasons] = useState([])
  const [selectedType, setSelectedType] = useState(defaultType === 'CR' ? 'Cost Reduction' : 'Elimination')
  const [notesRec, setNotesRec] = useState(null)
  const [keepsRec, setKeepsRec] = useState(null)
  const [grid, setGrid] = useState(null)
  const [editGrid, setEditGrid] = useState(null)
  const [savings, setSavings] = useState(null)
  const [savingsOpps, setSavingsOpps] = useState(null)
  const gridTypes = useRef([])
  const hcRecColumns = useRef(recColumns)
  const { token } = useAuth()
  const [authorities, setAuthorities] = useState(null)

  const blacklist = mapTypeToId([
    'PRI / Voice Circuit Scalability',
    'Wire Maintenance',
    'Cramming',
    'Landline Features',
    'Advertising & Listings',
    'Unused Wireless Devices',
    'Wireless Features',
    'Wireless Account Consolidation',
    'Tax',
    'Excessive Line Pricing',
    'Excessive Voice Pricing',
    'Service Upgrade',
    'Duplicate Line Billing',
    'Extra Surcharges	',
    'NARs Scalability',
    'Retro',
    'Excessive Data Pricing',
    'Excessive Long Distance Pricing',
    'Excessive Wireless Pricing',
    'Historical Core4',
    'Historical Wireless',
    'Long Distance Fees',
    'Excessive Fax Line Pricing'
  ])

  const graylist = [
    'Analog Circuits',
    'Excessive Internet',
    'Miscellaneous Data Connections'
  ]

  const fetchJob = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/data/health_check/id/${healthCheckId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => res.json())
  }

  const renderParams = {
    setNotesRec,
    cancelRec: (rec) => {
      rec.feedbackTypeId = 2
      rec.feedbackType = feedbackTypes.find(type => type.feedbackTypeId === 2)
      rec.assignedIpm = jobData.ipm
      fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify([rec])
      })
        .then(res => res.json())
        .then(res => {
          rec = { ...rec, ...res[0] }
          gridApi.getRowNode(rec.recommendationId).setData(rec)
          fetchJob().then(fetchedHc => {
            const hcJob = formatHealthCheckAsJob(fetchedHc)
            const { savingsCaptured } = calculateSavings(hcJob, 1)
            setNavSavings(savingsCaptured)
          })
        })
    },
    keepRec: (rec) => {
      const currentSummary = findSummary(rec)
      if (!blacklist.includes(currentSummary?.recommendationType?.recommendationTypeId)) {
        const newRec = { ...rec, assignedIpm: jobData.ipm, feedbackTypeId: 3, feedbackType: feedbackTypes.find(type => type.feedbackTypeId === 3) }
        setKeepsRec(newRec)
      } else {
        rec.assignedIpm = jobData.ipm
        rec.feedbackTypeId = 3
        rec.feedbackType = feedbackTypes.find(type => type.feedbackTypeId === 3)
        fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify([rec])
        })
          .then(res => res.json())
          .then(res => {
            rec = { ...rec, ...res[0] }
            gridApi.getRowNode(rec.recommendationId).setData(rec)
            fetchJob().then(fetchedHc => {
              const hcJob = formatHealthCheckAsJob(fetchedHc)
              const { savingsCaptured } = calculateSavings(hcJob, 1)
              setNavSavings(savingsCaptured)
            })
          })
      }
    },
    portRec: (rec) => {
      rec.feedbackTypeId = 4
      rec.feedbackType = feedbackTypes.find(type => type.feedbackTypeId === 4)
      fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify([rec])
      })
        .then(res => res.json())
        .then(res => {
          rec = { ...res[0], lineType: rec.lineType, recommendationCategory: rec.recommendationCategory, recommendationName: rec.recommendationName }
          gridApi.getRowNode(rec.recommendationId).setData(rec)
          fetchJob().then(fetchedHc => {
            const hcJob = formatHealthCheckAsJob(fetchedHc)
            const { savingsCaptured } = calculateSavings(hcJob, 1)
            setNavSavings(savingsCaptured)
          })
        })
    },
    undoRec: (rec) => {
      rec.feedbackTypeId = 1
      rec.feedbackType = feedbackTypes.find(type => type.feedbackTypeId === 1)
      rec.reasonNo = null
      fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify([rec])
      })
        .then(res => res.json())
        .then(res => {
          rec = { ...res[0], lineType: rec.lineType, recommendationCategory: rec.recommendationCategory, recommendationName: rec.recommendationName }
          gridApi.getRowNode(rec.recommendationId).setData(rec)
          fetchJob().then(fetchedHc => {
            const hcJob = formatHealthCheckAsJob(fetchedHc)
            const { savingsCaptured } = calculateSavings(hcJob, 1)
            setNavSavings(savingsCaptured)
          })
        })
    },
  }

  const findSummary = (rec) => {
    if (['Unused and Excessive Lines', 'Unused Lines', 'Excessive Lines'].includes(rec.recommendationName)) {
      const combined = gridData.find((sum) => sum.recSummaryId === 0)
      return combined[`${rec.lineType.toLowerCase()}Summary`]
    }
    return gridData.find((sum) => sum.recSummaryId === rec.recSummaryId)
  }

  const generateRowData = () => {
    const filterType = selectedType === "Elimination" ? "Eliminate" : "Cost Reduction"
    let test = gridData.reduce((prev, cur) => {
      return cur.recommendationType.category === filterType ? [...prev, ...cur.recommendations] : prev
    }, [])
    return test
  }

  const generateColumns = () => {
    const fullColumns = gridColumns.map((c) => {
      const colDef = recColumns.find((recCol) => recCol.field === c.colId)
      if (colDef) {
        return { ...c, ...colDef }
      }
      return c
    })
    return fullColumns.map((c, i) => {
      return <AgGridColumn
        aggFunc={c.aggFunc}
        cellClassRules={feedbackCellClassRules}
        cellRenderer={c.renderer ? c.renderer : null}
        cellRendererParams={c.renderParams ? generateRenderParams(c.renderParams) : null}
        enableRowGroup
        field={c.field}
        filter
        floatingFilter
        flex={c.flex}
        headerName={c.name}
        key={c.colId}
        resizable
        sortable
        valueFormatter={c.formatter ? formatters[c.formatter] : null}
        valueGetter={c.getter ? getters[c.getter] : null}
        hide={c.hide}
        pinned={c.pinned}
        pivot={c.pivot}
        pivotIndex={c.pivotIndex}
        rowDrag={i === 0}
        rowGroup={c.rowGroup}
        rowGroupIndex={c.rowGroupIndex}
        sort={c.sort}
        sortIndex={c.sortIndex}
        width={c.width}
      />
    })
  }

  const filterEmptyColumns = (cols) => {
    return cols.filter(c => isNaN(+c.colId))
  }

  const saveCurrentColumnState = () => {
    const colState = gridColumnApi ? gridColumnApi.getColumnState() : gridColumns
    setGridColumns(filterEmptyColumns(colState))
  }

  const generateRenderParams = (paramsArr) => {
    const params = {}
    paramsArr.forEach((p) => {
      if (p !== 'reduceRec' && p !== 'researchRec') {
        params[p] = renderParams[p]
      }
    })
    params['saveCurrentColumnState'] = saveCurrentColumnState
    return params
  }

  const resetColumns = (type = selectedType) => {
    return hcRecColumns.current.filter(c => !c.for || c.for === type)
      .sort((a, b) => {
        if (type === "Elimination") {
          return a.eliminationIndex - b.eliminationIndex
        }
        return a.reductionIndex - b.reductionIndex
      })
      .map(c => ({
        ...c,
        hide: false,
        pinned: false,
        pivot: false,
        rowGroup: false,
        sort: false
      }))
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const onFirstDataRendered = () => {
    if (gridApi) {
      gridApi.setFilterModel(initialGridFilters)
    }

    if (gridColumnApi) {
      gridColumnApi.autoSizeAllColumns();
    }
  }

  const updateColumnState = (e) => {
    if (e.type === 'dragStopped' && e.target.className !== 'ag-header-cell-resize') { return }
    if (gridColumnApi) {
      setGridColumns(filterEmptyColumns(gridColumnApi.getColumnState()))
    }
  }

  const saveGridState = (name = '', resource = 0, allJobs = 0) => {
    const columnState = filterEmptyColumns(gridColumnApi.getColumnState())
    const filterState = gridApi.getFilterModel()
    const reqBody = {
      active: 1,
      columns: JSON.stringify(columnState),
      filters: JSON.stringify(filterState),
      spycareJobId: allJobs ? null : spycareJob,
      jobId: -1,
      name,
      gridTypeId: gridTypes.current.find(type => type.name === selectedType).gridTypeId,
      gridSubtypeId: 1
    }

    fetch(`${process.env.REACT_APP_API_URL}/data/grid?resource=${resource}&spycare=1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(reqBody)
    })
      .then(res => res.json())
      .then(res => {
        setGrid(null)
        setGridConfigs([...gridConfigs, res])
        setGridConfig(res.gridId)
      })
  }

  const editGridState = (resource = 0) => {
    const columnState = filterEmptyColumns(gridColumnApi.getColumnState())
    const filterState = gridApi.getFilterModel()
    const reqBody = {
      ...editGrid,
      columns: JSON.stringify(columnState),
      filters: JSON.stringify(filterState),
    }
    fetch(`${process.env.REACT_APP_API_URL}/data/grid`, {
      method: 'PUT',
      body: JSON.stringify(reqBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(grid => {
        setGridConfigs(gridConfigs.map(g => {
          if (g.gridId == grid.gridId) {
            return grid
          }
          return g
        }))
        setEditGrid(null)
      })
  }

  const loadGridState = (configId) => {
    const config = gridConfigs.find(c => c.gridId == configId)
    if (config) {
      const columns = JSON.parse(config.columns)
      const filters = JSON.parse(config.filters)
      const loadedColumns = columns.map(col => {
        const matchingCol = recColumns.find(c => c.field === col.colId)
        return { ...matchingCol, ...col }
      })
      setGridColumns(loadedColumns)
      gridApi.setFilterModel(filters)
    }
  }

  useEffect(() => {
    if (spycareJob) {
      fetch(`${process.env.REACT_APP_API_URL}/data/health_check/spycare_job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(healthChecks => {
          healthChecks.sort((a, b) => b.healthCheckId - a.healthCheckId)
          const hcId = healthCheckId ? healthCheckId : (healthChecks.length && healthChecks[0].healthCheckId) || null
          if (healthChecks.length && healthChecks[0].healthCheckId != hcId) {
            hcRecColumns.current = recColumns.filter(col => col.field !== 'updateFeedback')
          }
          const healthCheck = healthChecks.find(hc => hc.healthCheckId == hcId)
          const healthCheckAsJob = formatHealthCheckAsJob(healthCheck)
          const summaries = healthCheckAsJob.recSummaries
          const unusedLinesIndex = summaries.findIndex((summary) => summary.recommendationType.recommendationTypeId === 1)
          const unusedLines = unusedLinesIndex > -1 ? summaries[unusedLinesIndex] : null
          let excessiveLinesIndex = summaries.findIndex((summary => summary.recommendationType.recommendationTypeId === 2))
          const excessiveLines = excessiveLinesIndex > -1 ? summaries[excessiveLinesIndex] : null
          let combinedLines = null
          if (unusedLines || excessiveLines) {
            combinedLines = {
              recommendationType: {
                category: 'Eliminate',
                name: 'Unused and Excessive Lines'
              },
              recommendations: [],
              recSummaryId: 0,
            }

            if (unusedLines) {
              combinedLines.job = unusedLines.job
              combinedLines.recommendationTypeId = 1
              combinedLines.recommendationType.recommendationTypeId = 1
              unusedLines.recommendations.forEach((rec) => {
                rec.lineType = 'Unused'
                rec.recommendationName = 'Unused Lines'
              })
              combinedLines.recommendations = [...unusedLines.recommendations]
              combinedLines.unusedSummary = unusedLines
            }
            if (excessiveLines) {
              combinedLines.job = combinedLines.job ? combinedLines.job : excessiveLines.job
              combinedLines.recommendationTypeId = combinedLines.recommendationTypeId ? combinedLines.recommendationTypeId : 2
              combinedLines.recommendationType.recommendationTypeId = combinedLines.recommendationType.recommendationTypeId ? combinedLines.recommendationType.recommendationTypeId : 2
              excessiveLines.recommendations.forEach((rec) => {
                rec.lineType = 'Excessive'
                rec.recommendationName = 'Excessive Lines'
              })
              combinedLines.recommendations = [...combinedLines.recommendations, ...excessiveLines.recommendations]
              combinedLines.excessiveSummary = excessiveLines
            }
            combinedLines.recommendations.forEach((rec) => {
              rec.recommendationCategory = 'Elimination'
            })
          }
          const filteredSummaries = summaries.filter((s) => ![1, 2].includes(s.recommendationType.recommendationTypeId))
          filteredSummaries.forEach((sum) => {
            const category = sum.recommendationType.category === 'Eliminate' ? 'Elimination' : sum.recommendationType.category
            const name = sum.recommendationType.name
            sum.recommendations.forEach((rec) => {
              rec.recommendationCategory = category
              rec.recommendationName = recTypes[name]?.fullName
            })
          })
          if (combinedLines) { filteredSummaries.unshift(combinedLines) }
          const { savingsCaptured, openRecCount } = calculateSavings(healthCheckAsJob, 1)

          filteredSummaries.forEach((sum) => {
            sum.recommendations.forEach((rec) => {
              rec.monthlyCost = rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax
            })
          })
          setGridData(filteredSummaries.filter((sum) => Object.keys(recTypes).includes(sum.recommendationType.name)))
          setSavings(savingsCaptured)
          setSavingsOpps(openRecCount)
          setJobData(healthCheckAsJob)
        })
        .catch(({ message }) => { })
    }
  }, [spycareJob, healthCheckId, recType])

  useEffect(() => {
    if (spycareJob) {
      fetch(`${process.env.REACT_APP_API_URL}/data/grid/spycare_job/${spycareJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(grids => {
          if (grids.length) {
            setGridConfigs(grids)
            if (gridId) {
              const defaultConfig = grids.find(g => g.gridId == gridId)
              const columns = JSON.parse(defaultConfig.columns)
              const filters = JSON.parse(defaultConfig.filters)
              const loadedColumns = columns.map(col => {
                const matchingCol = recColumns.find(c => c.field === col.colId)
                return { ...matchingCol, ...col }
              })
              setSelectedType(defaultConfig.gridType.name)
              setGridColumns(loadedColumns)
              setInitialGridFilters(filters)
              setGridConfig(gridId)
            } else {
              setGridColumns(resetColumns())
              setInitialGridFilters(recType ? { recommendationName: {
                values: recType === 'Unused and Excessive Lines' ? ['Unused Lines', 'Excessive Lines'] : [recType]
              }, filterType: 'set' } : {})
            }
          } else {
            setGridColumns(resetColumns())
            setInitialGridFilters(recType ? { recommendationName: {
              values: recType === 'Unused and Excessive Lines' ? ['Unused Lines', 'Excessive Lines'] : [recType]
            }, filterType: 'set' } : {})
          }
        })
    } else {
      setGridColumns(resetColumns())
      setInitialGridFilters({})
    }
  }, [spycareJob, gridId])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/feedback_type`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(types => setFeedbackTypes(types))

    fetch(`${process.env.REACT_APP_API_URL}/data/reason_no`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(reasons => setReasons(reasons))

    fetch(`${process.env.REACT_APP_API_URL}/data/grid_type/grid_subtype/1`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(types => {
        gridTypes.current = types
      })
  }, [])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/user/me`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(user => setAuthorities(user.authorities))
  }, [token])

  const statusBar = {
    statusPanels: [
        {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
                aggFuncs: ['avg', 'count', 'min', 'max', 'sum']
            }
        }
    ]
  }

  return (
    <>
      <DashboardNav />
      <div className="position-relative">
        <Container className="mt-3">
          <Row>
            <Col xs={12} className="d-xl-flex justify-content-between align-content-center">
              <div className={`${styles.healthCheckHeader} d-flex mb-0`}>
                <h1 className="mb-0">
                  HealthCheck Recommendations
                </h1>
                <a
                  className={`${styles.keyTooltipButton} ms-2`}
                  data-tip
                  data-for="keyTip"
                >
                  <FontAwesomeIcon icon={["fas", "info-circle"]}/>
                </a>
                <ReactTooltip id="keyTip" place="bottom" effect="solid">
                  <Card.Body className={`${styles.keyTooltipInner} d-flex align-items-center mb-0`}>
                    <div className="cardTitleContainer">
                      <Card.Title className={`${styles.tooltipHeader}`}>Key</Card.Title>
                    </div>
                    <Card.Text>
                      <Row>
                        <Col xs={12} lg={3}>
                          <p><span className="color-warning-alt"><strong>Open</strong></span>, indicates that you haven’t yet responded with a decision for these recommendations.</p>
                        </Col>
                        <Col xs={12} lg={3}>
                          <p><span className="color-cancel"><strong>Keep</strong></span>, indicates that you have decided recommendations shouldn’t be implemented.</p>
                        </Col>
                        <Col xs={12} lg={3}>
                          <p><span className="color-greenNormal"><strong>Reduce</strong></span>, indicates that you have decided elimination recommendations should be cost reductions instead.</p>
                        </Col>
                        <Col xs={12} lg={3}>
                          <p><span className="color-green"><strong>Cancel</strong></span>, indicates that SpyGlass has saved you money by implementing recommendations that you approved.</p>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </ReactTooltip>
              </div>
              <div className={`${styles.tableControlsContainer}`}>
                <div className={styles.tableControls}>
                  <div>
                    <Tab.Container id="list-group-tabs" activeKey={selectedType} >
                      <ListGroup horizontal>
                        <ListGroup.Item
                          eventKey="Elimination"
                          action
                          onClick={() => {
                            if (selectedType !== 'Elimination') {
                              setGridConfig(null)
                              setGridColumns(resetColumns('Elimination'))
                              gridApi.setFilterModel({})
                            }
                            setSelectedType('Elimination')
                          }}
                        >
                          Elimination
                        </ListGroup.Item>
                        <ListGroup.Item
                          variant="blueGreyExtraDark"
                          eventKey="Cost Reduction"
                          action
                          onClick={() => {
                            if (selectedType !== 'Cost Reduction') {
                              setGridConfig(null)
                              setGridColumns(resetColumns('Cost Reduction'))
                              gridApi.setFilterModel({})
                            }
                            setSelectedType('Cost Reduction')
                          }}
                        >
                          Cost Reduction
                        </ListGroup.Item>
                      </ListGroup>
                    </Tab.Container>
                  </div>
                  <div className={styles.gridControls}>
                    <Button
                      variant="outline-blueGreyDark"
                      onClick={() => {
                        setGridColumns(resetColumns())
                        gridApi.setFilterModel({})
                      }}
                    >
                      Reset
                    </Button>
                    <div className={styles.gridDropdown}>
                      <Form.Select
                        value={gridConfig || ''}
                        onChange={(e) => {
                          saveCurrentColumnState()
                          setGridConfig(e.target.value)
                        }}
                      >
                        <option value="">Select View</option>
                        {
                          gridConfigs.filter(c => gridTypes.current.length && c.gridTypeId === gridTypes.current.find(type => type.name === selectedType).gridTypeId).map(c => (
                            <option key={c.gridId} value={c.gridId}>
                              {c.name}
                            </option>
                          ))
                        }
                      </Form.Select>
                      <Button
                        className={styles.gridBtn}
                        variant="outline-blueGreyDark"
                        onClick={() => loadGridState(gridConfig)}
                        disabled={!gridConfig}
                      >
                        Load
                      </Button>
                      <Button
                        className={styles.gridBtn}
                        variant="outline-blueGreyDark"
                        onClick={() => setEditGrid(gridConfigs.find(g => g.gridId == gridConfig))}
                        disabled={!gridConfig}
                      >
                        Edit
                      </Button>
                      <Button
                        className={styles.gridBtn}
                        variant="cancel"
                        onClick={() => deleteGrid(gridConfig, () => {
                          fetch(`${process.env.REACT_APP_API_URL}/data/spycare/document/job/${spycareJob}`, {
                            headers: {
                              Authorization: `Bearer ${token}`
                            }
                          })
                            .then(res => res.json())
                            .then(docs => {
                              const resourceId = docs.find(d => d.fileName === `/recommendation/health-check?gridId=${gridConfig}`)?.spycareDocumentId
                              if (resourceId) {
                                fetch(`${process.env.REACT_APP_API_URL}/data/spycare/document/${resourceId}`, {
                                  method: 'DELETE',
                                  headers: {
                                    Authorization: `Bearer ${token}`
                                  }
                                })
                              }
                            })
                          setGridConfig(null)
                          setGridConfigs(gridConfigs.filter(g => g.gridId != gridConfig))
                        }, token)}
                        disabled={!gridConfig}
                      >
                        Delete
                      </Button>
                    </div>
                    <Button
                      variant="outline-blueGreyDark"
                      onClick={() => {
                        saveCurrentColumnState()
                        setGrid({ name: '', saveAsResource: false })
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-3 mb-0 px-0 mw-100">
        <Modal show={notesRec} onHide={() => setNotesRec(null)}
          dialogClassName="modal-w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h2>Reason No / Notes</h2>
          </Modal.Header>
          <div>
            <Modal.Body>
              <div>
                <div className={styles.notesSection}>
                  <label>Reason No</label>
                  <input type="text" value={notesRec?.reasonNo?.name || ''} disabled />
                </div>
                <div className={styles.notesSection}>
                  <label>Notes</label>
                  <input type="text" value={notesRec?.notes || ''} onChange={(e) => setNotesRec({ ...notesRec, notes: e.target.value })} />
                </div>
              </div>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <Button
              className="d-block mx-auto"
              variant="secondary"
              size="md"
              onClick={() => {
                fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                  body: JSON.stringify([notesRec])
                })
                  .then(res => res.json())
                  .then(res => {
                    gridApi.getRowNode(notesRec.recommendationId).setData(notesRec)
                    setNotesRec(null)
                    fetchJob().then(fetchedHc => {
                      const hcJob = formatHealthCheckAsJob(fetchedHc)
                      const { savingsCaptured } = calculateSavings(hcJob, 1)
                      setNavSavings(savingsCaptured)
                    })
                  })
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={keepsRec} onHide={() => setKeepsRec(null)}
          dialogClassName="modal-w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h2>Reason No?</h2>
          </Modal.Header>
          <div>
            <Modal.Body>
              <div>
                <div className={styles.keepSection}>
                  <label>Reason No</label>
                  <Form.Select value={keepsRec?.reasonNo?.reasonNoId || 0} onChange={(e) => { setKeepsRec({ ...keepsRec, reasonNo: reasons.find(reason => reason.reasonNoId == e.target.value) || null }) }}>
                    <option key={0} value={0}></option>
                    {graylist.includes(keepsRec?.recommendationName)
                      ? reasons.filter(reason => [1, 3, 5, 7, 8].includes(reason.reasonNoId))
                        .map(reason => (
                          <option key={reason.reasonNoId} value={reason.reasonNoId}>
                            {reason.name}
                          </option>
                        ))
                      : reasons.map(reason => (
                        <option key={reason.reasonNoId} value={reason.reasonNoId}>
                          {reason.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className={styles.keepSection}>
                  <label>Notes</label>
                  <input type="text" value={keepsRec?.notes || ''} onChange={(e) => setKeepsRec({ ...keepsRec, notes: e.target.value })} />
                </div>
              </div>
            </Modal.Body>
          </div>
          <Modal.Footer>
            <Button
              className="d-block mx-auto"
              variant="secondary"
              size="md"
              onClick={() => {
                const updatedRec = {
                  ...keepsRec,
                  assignedIpm: jobData.ipm,
                  feedbackTypeId: 3,
                  feedbackType: feedbackTypes.find(type => type.feedbackTypeId === 3),
                }
                fetch(`${process.env.REACT_APP_API_URL}/data/health_check_recommendation`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                  body: JSON.stringify([updatedRec])
                })
                  .then(res => res.json())
                  .then(res => {
                    gridApi.getRowNode(updatedRec.recommendationId).setData(updatedRec)
                    setKeepsRec(null)
                    fetchJob().then(fetchedHc => {
                      const hcJob = formatHealthCheckAsJob(fetchedHc)
                      const { savingsCaptured } = calculateSavings(hcJob, 1)
                      setNavSavings(savingsCaptured)
                    })
                  })
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <GridModal grid={grid} setGrid={setGrid} saveGridState={saveGridState}  authorities={authorities}/>
        <EditGridModal editGrid={editGrid} setEditGrid={setEditGrid} editGridState={editGridState} jobId={spycareJob} authorities={authorities} spyCare />
        <Row className="">
          <Col xs={12} lg={12}>
            <div className={`${styles.responsiveMt3__md} card-h-100 mb-0 shadow-none`}>
              {/*
              <Card.Header>
                <Row>
                  <div className="cardTitleContainer align-items-center d-block d-md-flex">
                    <Card.Title>Savings</Card.Title>
                    <div className=" d-none d-md-block p-3"></div>
                    <div className="statsContainerColumn justify-content-start p-3">
                      <div>
                        <Card.Title className="stats text-center">{formatAsCurrency(savings)}</Card.Title>
                        <Card.Text className="stats-descripton text-center">in Annual Savings</Card.Text>
                      </div>
                      <div>
                        <Card.Title className="stats text-center">{savingsOpps}</Card.Title>
                        <Card.Text className="stats-descripton text-center">Savings Opportunities Available</Card.Text>
                      </div>
                    </div>
                  </div>
                </Row>
              </Card.Header>
              */}
              <Card.Body className="py-0">
                <div className="table-container" style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* Height was 68vh when cards were on left */}
                  <div className="ag-theme-alpine p-0 pb-3" style={{ height: 'calc(100vh - 196px)', width: '100vw', padding: '3em' }}>
                    {(gridData && gridColumns && initialGridFilters)
                      ? (
                        <AgGridReact
                          ref={gridRef}
                          rowData={generateRowData()}
                          frameworkComponents={renderers}
                          rowGroupPanelShow="always"
                          enableRangeSelection
                          sideBar
                          statusBar={statusBar}
                          rowDragManaged={true}
                          animateRows={true}
                          onGridReady={onGridReady}
                          onFirstDataRendered={onFirstDataRendered}
                          onColumnVisible={updateColumnState}
                          onColumnPinned={updateColumnState}
                          onColumnMoved={updateColumnState}
                          onColumnPivotChanged={updateColumnState}
                          onDragStopped={updateColumnState}
                          onColumnRowGroupChanged={updateColumnState}
                          onSortChanged={updateColumnState}
                          getRowNodeId={row => row.recommendationId}
                        >
                          {generateColumns()}
                        </AgGridReact>
                      )
                      : null}
                  </div>
                </div>
              </Card.Body>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HealthCheckRecommendations
