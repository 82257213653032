import React from "react";
import DashboardNav from "../components/DashboardNav";
import { Container, Row, Col, Card } from "react-bootstrap";
import HubspotContactForm from "../components/HubspotContactForm";

const SpycareHelpDesk = () => {
    return (
        <>
            <DashboardNav/>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h1>Help Desk</h1>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <HubspotContactForm/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SpycareHelpDesk