import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { LicenseManager } from "ag-grid-enterprise";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import reportWebVitals from './reportWebVitals';
import './index.scss';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY)

library.add(faUserCircle, faChevronDown, faBell, faFileUpload, faCalendarAlt, faExclamationCircle, faSearch, faCheckSquare, faEnvelope, faLock, faInfoCircle)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
