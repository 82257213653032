import React, { useState } from 'react';
import styles from './SearchBar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchBar = (props) => {
    const [searchText, setSearchText] = useState('');
    const textPlaceholder = "Search File Name";

    const handleChange = (e) => {
        setSearchText(e.target.value);

        props.callback(e.target.value);
    }

    return (
        <div className={styles.searchBar}>
            <input type="text" value={searchText} placeholder={textPlaceholder} onChange={handleChange}/>
            <div className={styles.icon}><FontAwesomeIcon icon="search" /></div>
        </div>
    );
}

export default SearchBar;