import Tabs from './Tabs';
import DocumentManagerTabItem from './DocumentManagerTabItem';
import { groupBy } from '../utils';
import styles from './DocumentManager.module.scss'

const DocumentManager = ({docs}) => {
    const sortedDocs = docs.sort((a, b) => (a.documentName.toLowerCase() > b.documentName.toLowerCase())
    ? 1 
    : ((b.documentName.toLowerCase() > a.documentName.toLowerCase()) 
        ? -1 
        : 0));
    const groupedDocs = groupBy(sortedDocs, 'documentType');

    const tabItems = Object.entries(groupedDocs).sort((a, b) => { 
        const sortOrders = [
            {id: 1, name: 'New'},
            {id: 2, name: 'Resources'},
            {id: 3, name: 'SpyGlass Agreements'},
            {id: 3, name: 'SpyCare Agreements'},
            {id: 4, name: 'Carrier Invoices'},
            {id: 5, name: 'Carrier Contracts'},
            {id: 6, name: 'Audit Materials'},
            {id: 7, name: 'Miscellaneous'},
            {id: 8, name: 'Customer Uploads'}
        ];
        return sortOrders.find(so => so.name === a[0]).id > sortOrders.find(so => so.name === b[0]).id ? 1 : -1;
    }).map(([key, value], index) => {
        return (
            <div label={key} key={index}>
                <DocumentManagerTabItem data={value} key={index}/>
            </div>
        );
    });

    return (
        <div className={styles.documentManager}>
            <div className={styles.tabsContainer}>
                {tabItems && Object.keys(tabItems).length
                ? 
                    <Tabs>
                        {tabItems}
                    </Tabs>
                :   null
                }
            </div>
        </div>
    );
}
export default DocumentManager;