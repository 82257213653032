import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const HandleJob = ({ setJobId, setSpycareJobId, setPortal, setRecAccess, setInvAccess, setIbaAccess }) => {
  const { jobId } = useParams()
  const { token, user } = useAuth()
  useEffect(() => {
    if (jobId) {
      setJobId(jobId)
      setPortal('SpyGlass')
      fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/snapshot/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.text())
        .then(data => {
          const spycareJob = data ? JSON.parse(data) : null
          setSpycareJobId(spycareJob ? spycareJob.spycareJobId.toString() : null)
        })
      if (user.authorities.includes('ROLE_Employee')) {
        setRecAccess(1)
        setInvAccess(1)
        setIbaAccess(1)
      }
      else {
        fetch(`${process.env.REACT_APP_API_URL}/data/job_no_summary/id/${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => res.json())
        .then(data => {
          console.log(data)
          setRecAccess(data.recommendationsAccess)
          setInvAccess(data.inventoryAccess)
          setIbaAccess(data.reportEnabled)
        })
      }
    }
  }, [jobId, setJobId, setSpycareJobId, setPortal, setRecAccess, setInvAccess, setIbaAccess, user, token])

  return <Redirect to="/" />
}

export default HandleJob