import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
import styles from './DashboardRecommendationProgress.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatAsCurrency } from '../utils/'

// Example of Total in Tooltip = https://jsfiddle.net/uqws6e8r/
// Example of Total above Stacked Bar = https://codepen.io/k-sav/pen/PXxywK
// Documentation: https://react-chartjs-2.netlify.app/components/bar

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ chartData }) => {
  const options = {
    indexAxis: 'y',
    responsive: true,
    //aspectRatio: 4.75,
    aspectRatio: 5.75,
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart - Stacked',
      },
      tooltip: {
        //mode: 'point',
        enabled: true,
        xAlign: "right",
        yAlign: "center",
        callbacks: {
          label: (item) => formatAsCurrency(item.raw)
        }
      },
      legend: {
        align: 'start',
        labels: {
          boxWidth: 16
        }
      },
      datalabels: {
        color: '#000',
        align: "center"
      }
    },
    interaction: {
      mode: 'point',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        position: 'right',
        ticks: {
          callback: function () {
            if (!chartData) { return null }
            const legendItems = this.chart.legend.legendItems
            let total = 0
            if (!legendItems[0].hidden) {
              total += chartData.savingsCaptured
            }
            if (!legendItems[1].hidden) {
              total += chartData.savingsDeclined
            }
            if (!legendItems[2].hidden) {
              total += chartData.openOpportunity
            }
            return formatAsCurrency(total)
          },
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      }
    },
  }

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Accepted',
        data: [chartData?.savingsCaptured],
        backgroundColor: '#2BBD7E',
        stack: 'Stack 0',
      },
      {
        label: 'Declined',
        data: [chartData?.savingsDeclined],
        backgroundColor: '#FF1744',
        stack: 'Stack 0',
      },
      {
        label: 'Remaining',
        data: [chartData?.openOpportunity],
        backgroundColor: '#FFAD42',
        stack: 'Stack 0',
      },
    ],
  }

  return (
    <>
      <div className={styles.barContainer}>
        <div className={styles.chartOuter}>
          <Bar options={options} data={data} />
        </div>
        {/*
      <div className={styles.chartSum}>
        <h6>$100,571.88</h6>
      </div>
      */}
      </div>
      <div className={styles.openContainer}>
        <h5>
          {formatAsCurrency(chartData?.openOpportunity)} <FontAwesomeIcon icon="exclamation-circle" className="color-notification" />
        </h5>
        <h6>
          Savings Opportunity Remaining
        </h6>
      </div>
    </>
  )
}

export default BarChart;
