import React from 'react';
import styles from './Tab.module.scss';

const Tab = (props) => {
    const onClick = () => {
        const { label, onClick } = props;
        onClick(label);
      }

    const type = props.activeTab === props.label ? 'tabListActive' : 'tabListItem';

    return (
      <li
        className={`${styles[type]}`}
        onClick={onClick}
      >
        <div>{props.label}</div>
      </li>
    );
}

export default Tab;