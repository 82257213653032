import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import styles from './GridModal.module.scss';

const EditGridModal = ({ editGrid, setEditGrid, editGridState, jobId, spyCare = 0, authorities }) => {
  return (
    <Modal show={editGrid} onHide={() => setEditGrid(null)}
      dialogClassName="modal-w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h2>Edit View</h2>
      </Modal.Header>
      <div>
        <Modal.Body>
          <div>
            <div className={styles.gridSection}>
              <label>Name</label>
              <input type="text" value={editGrid?.name || ''} onChange={(e) => { setEditGrid({ ...editGrid, name: e.target.value }) }} />
            </div>
            {/* {
              authorities?.includes("ROLE_Employee")
                ?
                  <> */}
                    <div className={styles.gridSection}>
                      <label>Save as Resource?</label>
                      <input type="checkbox" checked={editGrid?.saveAsResource || false} onChange={(e) => { setEditGrid({ ...editGrid, saveAsResource: e.target.checked }) }} disabled />
                    </div>
                    <div className={styles.gridSection}>
                      <label>Save for All Jobs?</label>
                      <input type="checkbox" checked={spyCare ? !editGrid?.spycareJobId || false : !editGrid?.jobId || false}
                        onChange={(e) => {
                          if (spyCare) {
                            setEditGrid({ ...editGrid, spycareJobId: e.target.checked ? null : jobId })
                          } else {
                            setEditGrid({ ...editGrid, jobId: e.target.checked ? null : jobId })
                          }
                        }}
                      />
                    </div>
                  {/* </>
                : null
            } */}
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button
          className="d-block mx-auto"
          variant="secondary"
          size="md"
          onClick={() => {
            editGridState(editGrid.saveAsResource ? 1 : 0)
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditGridModal