import React, { useContext, createContext } from 'react';

const SavingsContext = createContext()
export const useSavings = () => useContext(SavingsContext)

const SavingsProvider = ({ children, value = null }) => (
  <SavingsContext.Provider value={value}>
    {children}
  </SavingsContext.Provider>
)

export default SavingsProvider
