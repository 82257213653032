import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import styles from './GridModal.module.scss';

const GridModal = ({ grid, setGrid, saveGridState, authorities }) => {
  return (
    <Modal show={grid} onHide={() => setGrid(null)}
      dialogClassName="modal-w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h2>Save View</h2>
      </Modal.Header>
      <div>
        <Modal.Body>
          <div>
            <div className={styles.gridSection}>
              <label>Name</label>
              <input type="text" value={grid?.name || ''} onChange={(e) => { setGrid({ ...grid, name: e.target.value }) }} />
            </div>
            {
              authorities?.includes("ROLE_Employee")
                ?
                  <>
                    <div className={styles.gridSection}>
                      <label>Save as Resource?</label>
                      <input type="checkbox" checked={grid?.saveAsResource || false} onChange={(e) => { setGrid({ ...grid, saveAsResource: e.target.checked }) }} />
                    </div>
                    <div className={styles.gridSection}>
                      <label>Save for All Jobs?</label>
                      <input type="checkbox" checked={grid?.saveAllJobs || false} onChange={(e) => { setGrid({ ...grid, saveAllJobs: e.target.checked }) }} />
                    </div>
                  </>
                : null
            }
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button
          className="d-block mx-auto"
          variant="secondary"
          size="md"
          onClick={() => {
            saveGridState(grid.name, grid.saveAsResource ? 1 : 0, grid.saveAllJobs ? 1 : 0)
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GridModal