import React, { useContext, createContext } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../components/AuthProvider';
import { useJob } from '../components/JobProvider';

const PortalContext = createContext()
export const usePortal = () => useContext(PortalContext)

const PortalProvider = ({ children, value = null, setPortal}) => {
  const { token, user } = useAuth()
  const { spycareJobId: spycareJob } = useJob()

  useEffect(() => {
    if (value === null && token) {
      setPortal(user.authorities.includes('ROLE_Customer')
      && spycareJob
        ? 'SpyCare' 
        : 'SpyGlass'
      )
    }
  }, [setPortal, user, token, value, spycareJob])
  return (
    <PortalContext.Provider value={value}>
      {children}
    </PortalContext.Provider>
  )
}

export default PortalProvider
