import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useRef} from 'react';

const FileUploadButton = (props) => {
    const fileInputRef = useRef();

    const handleChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        props.callback(e.target.files);
    }

    return (
        <>
        <input type="file" hidden multiple ref={fileInputRef} onChange={handleChange}/>
        <Button variant="outline-primary" onClick={() => fileInputRef.current.click()}>
            Upload Files <FontAwesomeIcon icon="file-upload" />
        </Button>
        </>
    )
}

export default FileUploadButton;