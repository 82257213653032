import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import DashboardNav from './DashboardNav';
import ProjectTracker from './ProjectTracker';
import BarChart from './DashboardRecommendationProgress';
import { CircularProgressbar } from 'react-circular-progressbar';
import moment from 'moment';
import { formatTeam, getProjectStatus, appendOrdinalSuffix } from '../utils'
import { calculateSavings } from '../utils/calculate';
import 'react-circular-progressbar/dist/styles.css';
import styles from './SpyGlassDashboard.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useJob } from './JobProvider';
import FileUploadButton from './FileUploadButton';
import { SimpleGauge } from 'react-simple-gauges';
import { useAuth } from './AuthProvider';
import { useAccess } from './AccessProvider'
import { AgGridReact } from 'ag-grid-react';
import ResourcesDataCell from './ResourcesDataCell';

const Dashboard = () => {
  const [data, setData] = useState(null)
  const [clientName, setClientName] = useState(null)
  const [projectStatus, setProjectStatus] = useState(null)
  const [upcomingMeeting, setUpcomingMeeting] = useState(null)
  const [teamMembers, setTeamMembers] = useState([])
  const [resources, setResources] = useState([])
  const [percentile, setPercentile] = useState(null)
  const [meetingLink, setMeetingLink] = useState(null)
  const { jobId: job } = useJob()
  const { token } = useAuth()
  const { ibaAccess } = useAccess()

  useEffect(() => {
    if (job) {
      fetch(`${process.env.REACT_APP_API_URL}/data/job/${job}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(job => {
          const { implementedRecCount, totalRecCount, savingsCaptured, savingsPossible, savingsDeclined, openOpportunity } = calculateSavings(job)

          setData({
            implementedRecCount, totalRecCount, savingsCaptured, savingsPossible, savingsDeclined, openOpportunity
          })

          setProjectStatus(getProjectStatus(job.jobStatus.jobStatusId))

          setClientName(job.client?.clientName)

          let upcoming = null
          let meetingDates = [
            { name: 'today', date: moment().format('YYYY-MM-DD') },
            { name: job.customMeetingTitle || 'Custom', date: job.dateCustomMeeting },
            { name: 'KOM', date: job.dateKom },
            { name: 'SOF', date: job.dateSof },
          ]
          meetingDates = meetingDates.filter((d) => d.date).sort((a, b) => {
            if (a.date === b.date) {
              if (a.name === 'today') { return -1 }
              else { return 1 }
            }
            return a.date < b.date ? -1 : 1
          })

          const todayIdx = meetingDates.findIndex((d) => d.name === 'today')
          if (todayIdx !== meetingDates.length - 1) {
            upcoming = meetingDates[todayIdx + 1]
          }

          setUpcomingMeeting(upcoming ? { name: upcoming.name || '', date: moment(upcoming.date).format('MM-DD-YYYY') } : null)

          console.log(job.documents.filter(doc => doc.documentTypeId === 8))
          setResources(job.documents.filter(doc => doc.documentTypeId === 8))
        })
        .catch(err => {
          setData({})
          setProjectStatus('materials')
        })

      fetch(`${process.env.REACT_APP_API_URL}/data/job/${job}/team`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(members => {
          const memberArr = formatTeam(members)
          setTeamMembers(memberArr)
        })

      fetch(`${process.env.REACT_APP_API_URL}/data/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(user => {
          setMeetingLink(user.meetingLink);
        })
    }
  }, [job])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/data/percentile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(bucket => {
        setPercentile(bucket.find((p) => ((data?.savingsCaptured / data?.savingsPossible) * 100) <= p.capMax)?.percentile)
      })
  }, [data])

  const fileUploadButtonCallback = (files) => {
    if (job) {
      const formData = new FormData();
      formData.append("jobId", job);
      for (let i = 0; i < files.length; i++) {
        formData.append("files[" + i + "]", files[i]);
        formData.append("documentNames[" + i + "]", files[i].name);
      }

      fetch(`${process.env.REACT_APP_API_URL}/data/multipart_document`, {
        body: formData,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => res.json())
        .then(docs => {
        },
          (error) => {
            console.log(error);
          });
    }
  }

  const formatters = {
    'dateFormatter': (params) => (
      params.value ? moment(params.value).format('MM/DD/YYYY') : 'N/A'
    )
  }

  const onGridReady = (params) => {
    params.columnApi.autoSizeAllColumns();
  }

  return (
    <>
      <DashboardNav />
      <Container className="mt-3">
        <Row>
          <Col xs={12} className="d-flex justify-content-between align-content-center">
            <h1 className="mb-0">{clientName} Dashboard</h1>
            <div className="text-end">
              <FileUploadButton callback={fileUploadButtonCallback} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3 mb-3">
        <Row className="mb-3">
          <ProjectTracker status={projectStatus} meetingLink={meetingLink} ibaReady={ibaAccess} />
        </Row>
        {/*<div className={styles.cardContainer}>*/}
        <Row className={styles.cardContainer}>
          <Col xs={12} lg={4} xl={3}>
            <Card className="card-h-100">
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Meet Your SpyGlass Team</Card.Title>
                </div>
                {teamMembers.length ?
                  (<>
                    <div className={styles.projectLead}>
                      <div className={styles.pfp}>
                        <div className={styles.teamMemberPhoto} style={teamMembers[0].url ? { backgroundImage: `url(${teamMembers[0].url})` } : {}}></div>
                      </div>
                      <div className={styles.leadInfo}>
                        <h3 className="mb-1">{teamMembers[0].firstName} {teamMembers[0].lastName}</h3>
                        <h4 className="mb-1">{teamMembers[0].title}</h4>
                        {/*<div>Team</div>*/}
                        <div>{teamMembers[0].phone}</div>
                        <div className="color-primary"><a href={`mailto:${teamMembers[0].email}`}>{teamMembers[0].email}</a></div>
                      </div>
                    </div>
                    <div className={styles.teamMembers}>
                      {
                        teamMembers.slice(1).map((m) => (
                          <div className={styles.teamMemberRow} key={m.title}>
                            <div className={styles.teamMember}>
                              <div className={styles.ellipsisWrapper}>
                                <div className="d-inline-block me-2"><strong>{m.firstName} {m.lastName}</strong></div>
                                <div className="d-inline-block">{m.title}</div>
                              </div>
                              <div>
                                <div className={styles.email}><a href={`mailto:${m.email}`}><FontAwesomeIcon icon="envelope" /></a></div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))
                      }
                    </div>
                  </>)
                  : null}
              </Card.Body>
              <Card.Footer><Link to="/my-team" className="card-link">View My Team</Link></Card.Footer>
            </Card>
          </Col>
          {/*<div className={styles.cardGrid}>*/}
          <Col xs={12} lg={8} xl={6}>
            <Row className="card-h-50">
              <Col xs={12}>
                <Card className={`${styles.responsiveMt3__md} card-h-100`}>
                  <Card.Body>
                    <div className="cardTitleContainer">
                      <Card.Title>Recommendations Progress</Card.Title>
                    </div>
                    <BarChart chartData={data} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="card-h-50">
              <Col xs={12} lg={6}>
                <Card className="card-h-100">
                  <Card.Body>
                    <div className="cardTitleContainer">
                      <Card.Title>Savings Captured</Card.Title>
                    </div>
                    <div className={`${styles.statsContainerDashboard}`}>
                      {percentile
                        ?
                        <div className={styles.savingsCapturedContainer}>
                          <div className={styles.percentileGaugeContainer}>
                            <SimpleGauge percent={percentile} className={styles.percentileGauge} intervals={[40, 65, 90, 101]} colors={['#FF1744', '#FFAD42', '#00A9EC', '#2BBD7E']} />
                          </div>
                          <h5 className="mb-0"><strong>{appendOrdinalSuffix(percentile)} Percentile</strong></h5>
                          <div className={styles.gaugeLegend}>
                            <div className={styles.gaugeLegendItem}>
                              <h6>Low</h6>
                              <div className={styles.poorPercentile} />
                            </div>
                            <div className={styles.gaugeLegendItem}>
                              <h6>Average</h6>
                              <div className={styles.averagePercentile} />
                            </div>
                            <div className={styles.gaugeLegendItem}>
                              <h6>Good</h6>
                              <div className={styles.goodPercentile} />
                            </div>
                            <div className={styles.gaugeLegendItem}>
                              <h6>Excellent</h6>
                              <div className={styles.excellentPercentile} />
                            </div>
                          </div>
                        </div>
                        : "No Savings Captured Data"
                      }
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} lg={6}>
                <Card className={`${styles.responsiveMt3__md} card-h-100`}>
                  <Card.Body>
                    <div className="cardTitleContainer">
                      <Card.Title>Upcoming Meeting</Card.Title>
                    </div>
                    <div className={`${styles.statsContainerDashboard} statsContainer`}>
                      <div className={styles.meetingContainer}>
                        <div className={styles.meetingCalendar}>
                          <FontAwesomeIcon icon={["far", "calendar-alt"]} />
                        </div>
                        <div>
                          <div className={styles.bold}>
                            <h6 className="mb-1">{upcomingMeeting?.date}</h6>
                          </div>
                          <div className="text-center">
                            {upcomingMeeting?.name || 'None'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={3}>
            <Card className={`${styles.responsiveMt3__lg} card-h-100`}>
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Resources</Card.Title>
                </div>
                <div className={styles.resourcesContainer}>
                  {/* {
                    resources.map((r) => (
                      <div className={styles.resourceRow} key={r.documentId}>
                        <div className={styles.resource}>
                          <div>
                            {r.filetype && r.filetype.includes('pdf')
                              ? (<a href={r.url} rel="noreferrer noopener" target="_blank">{r.documentName}</a>)
                              : !r.base64
                                ? r.fileName.charAt(0) !== '/'
                                  ? (<a href={r.fileName} rel="noreferrer noopener" target="_blank">{r.documentName}</a>)
                                  : <Link to={r.fileName}>{r.documentName}</Link>
                                : (<Link to={r.fileName}>{r.documentName}</Link>)
                            }
                          </div>
                          <div className={styles[r.filetype && r.filetype.includes('pdf') ? 'PDF' : 'LINK']}>
                            {r.filetype && r.filetype.includes('pdf') ? 'PDF' : 'LINK'}
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  } */}
                  <div className="ag-theme-alpine p-0 pb-3" style={{ height: '50vh', width: '100%', padding: '3em', overflow: 'auto' }}>
                    <AgGridReact
                      rowData={resources}
                      //onGridReady={onGridReady}
                      onFirstDataRendered={onGridReady}
                      alwaysShowHorizontalScroll={true}
                      columnDefs={[
                        { 
                          headerName: "Resource",
                          field: "documentName",
                          cellRendererFramework: ResourcesDataCell,
                          sortable: true
                        },
                        {
                          headerName: "Date Uploaded",
                          field: "uploadDate",
                          valueFormatter: formatters['dateFormatter'],
                          sortable: true
                        }
                      ]}
                      >
                    </AgGridReact>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/*</div>*/}
      </Container>
    </>
  )
}

export default Dashboard