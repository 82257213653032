import React, { useState } from 'react';
import SearchBar from './SearchBar';
import DataTable from './DataTable'
import styles from './DocumentManagerTabItem.module.scss';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { usePortal } from './PortalProvider';

const DocumentManagerTabItem = (props) => {

    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const portal = usePortal();

    useEffect(() => {
        const filteredData = [];
        formatData(props.data).forEach((file) => {
            if (file.documentName.toLowerCase().includes(searchQuery.toLowerCase())) { 
                filteredData.push(file);
            }
        });
        setDisplayData(filteredData);
    }, [props.data, searchQuery])

    const handleSearchQuery = (query) => {
        const filteredData = [];
        formatData(props.data).forEach((file) => {
            if (file.documentName.toLowerCase().includes(query.toLowerCase())) { 
                filteredData.push(file);
            }
        });
        setSearchQuery(query);
        setDisplayData(filteredData);
    }

    const formatData = (d) => {
        return d.map(d => {
            return {
                ...d,
                displaySize: d.size !== null ? (d.size / (1024*1024)).toFixed(2) + ' MB' : 'N/A',
                displayType: d.filetype ? d.filetype : 'External Link',
                displayDate: d.uploadDate ? new Date(d.uploadDate).toLocaleDateString("default", {month: 'long', day: 'numeric', year: 'numeric'}) : null,
                viewFileButton: (() => {
                    return (
                        <Button variant='primary' onClick={() => window.open((d.size !== null ? d.url : d.fileName), '_blank')}>Details</Button>
                    )
                })
            }
        });
    }

    return (
        <div className={styles.cardNoPadding}>
        <div className={styles.searchBarContainer}>
            <SearchBar callback={handleSearchQuery}/>
        </div>
        <DataTable
            className={styles.documentTable}
            keyProp={portal === 'SpyGlass' ? "documentId" : "spycareDocumentId"}
            columns={[
                { label: "File Name", prop: "documentName" },
                { label: null, prop: "viewFileButton" },
                { label: "Size", prop: "displaySize" },
                { label: "Type", prop: "displayType" },
                { label: "Date Uploaded", prop: "displayDate" },
              ]}
              data={displayData}
        />
        </div>
    )
}

export default DocumentManagerTabItem;