import { useEffect } from "react";
import styles from './KOMMonthlySpendLegend.module.scss';

const KOMMonthlySpendLegend = ({chartData, sumCallback, countData}) => {

    const sum = chartData?.data?.datasets[0]?.data?.reduce((a, b) => {
        return a + b;
    }, 0);

    const legendData = chartData?.options?.plugins.legend.labels.generateLabels(chartData).map((item, index) => {
        return { color: item.fillStyle, data: chartData.data.datasets[0].data[index].toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), name: item.text };
    }).sort((a, b) => b.data - a.data);

    useEffect(() => {
        if (sum) {
          sumCallback(sum?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }
    })

    return(
        <div className={`${styles.legendTable} d-flex flex-column mt-3 `}>
          <div className={`${styles.legendHeader} d-flex flex-row justify-content-between pt-1 pb-1 ps-2 pe-2`}>
            <div>Provider [#]</div>
            <div>Total (%)</div>

          </div>
            {legendData?.map((item, index) => {
              return(
                <div key={index} className={`${styles.legendRow} d-flex flex-row justify-content-between pt-1 pb-1 ps-2 pe-2`}>
                  <div className={`${styles.legendId} d-flex flex-row align-items-center`}><div className={`${styles.legendColor} me-2`} style={{background: item.color}}></div> {item.name} [{countData[index]}]</div>
                  <div>${(item.data)} ({(parseFloat(item.data.replace(',', '')) / sum * 100).toFixed(2)}%)</div>
                </div>
              );
            })}
        </div>
    );
}
export default KOMMonthlySpendLegend;