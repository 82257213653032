// From https://stackoverflow.com/questions/69317628/how-to-insert-a-hubspot-form-in-a-react-js-app

import { React, useEffect, useState } from "react";
import { useJob } from './JobProvider';
import { useAuth } from './AuthProvider';

const HubspotContactForm = () => {
  const { spycareJobId: spycareJob } = useJob()
  const { token } = useAuth()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${spycareJob}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => res.json()).then(jobData => {
      const ipm = jobData.ipm || 'Owen Hood'
      fetch(`${process.env.REACT_APP_API_URL}/data/user/${ipm}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json()).then(data => {
        const email = data?.email || 'ohood@spyglass.net'

        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);
        setLoading(false)

        script.addEventListener('load', () => {
          // @TS-ignore
          if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
              region: "na1",
              portalId: "5956590",
              formId: "19242135-8f21-42da-84bd-9295bd62f2c6",
              target: '#hubspotForm'
            })
          }
        });

        window.addEventListener("message", function (event) {
          if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
            const iframe = document.getElementById('hs-form-iframe-0')
            const iframeDocument = iframe.contentWindow.document
            iframeDocument.querySelector('input[name="TICKET.account_manager"]').value = email
          }
        });
      })
    })
  }, []);

  return (
    <div>
      {loading ? 'Loading...' : ''}
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;