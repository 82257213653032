import React from 'react';
import styles from './UploadedFileView.module.scss';

export default function UploadedFileView(props) {
    const fileSizeInMB = (props.file.size / (1024*1024)).toFixed(2);
    const fileName = props.file.name;
    const docId = props.file.id;
    const docName = props.file.docName;

    const handleRemoveClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        props.callback(docId);
    }
    return (
        <div className={styles.uploadedFileView}>
            <div className={styles.fileName}>{docName}</div>
            <div className={styles.fileSize}>{fileSizeInMB}MB</div>
            <button className={styles.uploadedFileButton} onClick={() => window.open((props.file.size !== null ? props.file.url : props.file.fileName), '_blank')}>View Details</button>
            <button className={styles.uploadedFileButton} onClick={handleRemoveClick}>X</button>
        </div>
    );
}