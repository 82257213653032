import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Container, Row, Col, Card } from 'react-bootstrap/';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import IBAAfterAuditPateroChart from './IBAAfterAuditPateroChart.js';

import { formatAsCurrency } from '../utils';


Chart.register(...registerables, annotationPlugin);

let hasAnimatedAfter = false;

const IBAChartAfterAuditPDF = ({ postAuditSpend, savingsImplemented, implemented, score, benchmark, percentiles }) => {

  const benchmarkPercent = (benchmark * 100).toFixed(0);

  const data = {
    labels: [ '' ],
    datasets: [
      {
        data: [ 100 - 20 - (100 - (Number(benchmarkPercent) + 5)) ],
        backgroundColor: '#CC413A',
        barThickness: 50,
        borderRadius: {
          topLeft: 6,
          bottomLeft: 6
        },
        borderSkipped: false,
      },
      {
        data: [ 10 ],
        backgroundColor: '#E6881A',
        barThickness: 50,
      },
      {
        data: [ 10 ],
        backgroundColor: '#EBCD37',
        barThickness: 50,
      },
      {
        data: [ 100 - (Number(benchmarkPercent) + 5) ],
        backgroundColor: '#68A367',
        barThickness: 50,
        borderRadius: {
          topRight: 6,
          bottomRight: 6
        },
        borderSkipped: false,
      }
    ],
  };

  const options = {
    indexAxis: 'y',
    animation: {
      duration: hasAnimatedAfter ? 0 : 1000,
      onComplete: () => {
        hasAnimatedAfter = true;
      },
    },
    aspectRatio: 7.5,
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}%`,
          font: {
            size: '14'
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'Inefficient',
          font: {
            size: '14'
          },
        },
        position: 'left',
      },
      y2: {
        position: 'right',
        grid: {
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'Efficient',
          font: {
            size: '14'
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x',
            value: (benchmark * 100).toFixed(0),
            borderColor: 'black',
            borderWidth: 2,
            borderDash: [ 5, 5 ],
            label: {
              position: 'end',
              enabled: true,
              content: [ 'Benchmark:', `${benchmarkPercent}%` ],
              font: {
                size: '14'
              },
            },
          },
          point2: {
            type: 'point',
            pointStyle: 'triangle',
            scaleID: 'x',
            xValue: (benchmarkPercent),
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'none',
            radius: 5,
            yAdjust: 14,
          },
          point1: {
            type: 'point',
            pointStyle: 'triangle',
            scaleID: 'x',
            xValue: (score * 100).toFixed(0),
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'none',
            rotation: 180,
            radius: 5,
            yAdjust: 5,
          },
          label1: {
            type: 'label',
            xValue: (score * 100).toFixed(0),
            yAdjust: -20,
            borderRadius: 6,
            font: {
              weight: 'bold',
              size: '14',
            },
            backgroundColor: 'rgba(0,0,0,0.8)',
            color: 'white',
            content: [ 'My Score:', `${(score * 100).toFixed(0)}%` ],
          },
        },
      },
    },
  };

  // const [ modalShow, setModalShow ] = React.useState(false);

  // function AfterAuditModal(props) {
  //   return (
  //     <Modal
  //       {...props}
  //       size="lg"
  //       aria-labelledby="after-audit-modal"
  //       centered
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="after-audit-modal">
  //           After Audit
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <IBAAfterAuditPateroChart
  //           score={score}
  //           percentiles={percentiles}
  //           benchmark={benchmark}
  //         />
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={props.onHide}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="d-flex flex-row p-3 align-items-center">
            <Col className="col-3">
              <h3>After Audit</h3>
              <Row className="d-flex flex-row">
                <Col className="ps-0">
                  <div className="fw-bold">Post Audit Spend</div>
                </Col>
                <Col>
                  <div>{formatAsCurrency(postAuditSpend)}</div>
                </Col>
              </Row>
              <Row className="d-flex flex-row">
                <Col className="ps-0">
                  <div className="fw-bold">Savings Implemented</div>
                </Col>
                <Col>
                  <div>{formatAsCurrency(savingsImplemented)}</div>
                </Col>
              </Row>
              <Row className="d-flex flex-row">
                <Col className="ps-0">
                  <div className="fw-bold">% Implemented</div>
                </Col>
                <Col>
                  <div>{(implemented * 100).toFixed(2)}%</div>
                </Col>
              </Row>
            </Col>
            <Col className="col-9">
              <div>
                <Bar options={options} data={data} />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row className="p-3">
            <h3>After Audit</h3>
            <div className="w-50 m-auto">
              <IBAAfterAuditPateroChart
                score={score}
                percentiles={percentiles}
                benchmark={benchmark}
              />
            </div>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default IBAChartAfterAuditPDF;
