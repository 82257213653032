import React from 'react';
import { useHistory } from 'react-router-dom';
import { useJob } from './JobProvider';
import styles from './ProjectTracker.module.scss';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ProjectTracker = ({ status, meetingLink, ibaReady }) => {
  const history = useHistory()
  const { jobId: job } = useJob();
  let text = ''
  let link = ''
  let disabled = false
  let isExternal = false
  switch (status) {
    case 'materials':
      text = 'Go to Upload'
      link = 'upload'
      break
    case 'kom':
      text = 'Go to Kick-off Meeting'
      link = 'kickoff-meeting'
      break
    case 'analysis':
      text = 'Need to Talk? Schedule Meeting'
      link = meetingLink
      isExternal = true
      break
    case 'sof':
      text = 'Go to Summary of Findings'
      link = 'recommendation-summary'
      break
    case 'decisions':
      text = 'Go to Decisions & Implementations'
      link = 'recommendation-summary'
      break
    case 'iba':
      text = 'Go to Industry Benchmark Analysis'
      link = `report`
      if (!ibaReady) {
        disabled = true
        text = 'Industry Benchmark Analysis'
        link = ''
      }
      break
    default:
      text = 'Go to Kick-off Meeting'
      link = 'kickoff-meeting'
  }

  return (
    <Col xs={12}>
      <Card var="dark" bg="dark" className="card-h-33">
        <Card.Body>
          <Row className="align-items-center">
            <Col xs={12} lg={3}>
              <div className={styles.trackerStart}>
                {status !== null
                  ? (<div>
                    <h2>Project Tracker</h2>
                    {
                      !isExternal
                        ?
                        <Button variant="secondary" size="lg" className="mt-3" disabled={disabled} onClick={() => { history.push(`/${link}`) }}>
                          {`${text} ${disabled ? '' : ''}`}
                        </Button>
                        :
                        <Button variant="secondary" size="lg" className="mt-3" disabled={disabled} href={link} target="_blank" rel="noopener noreferrer">
                          {`${text} ${disabled ? '' : ''}`}
                        </Button>
                    }
                  </div>) : null}
              </div>
            </Col>
            <Col xs={12} lg={9}>
              <div className={styles.trackerGraphicContainer}>
                <img className={styles.tracker} src={`/project-tracker/otis-project-tracker-${status}.png`} alt="" />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default ProjectTracker