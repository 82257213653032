import React from 'react';
import { usePortal } from '../components/PortalProvider';
import SpyGlassDashboard from '../components/SpyGlassDashboard';
import SpyCareDashboard from '../components/SpyCareDashboard';

const Dashboard = () => {
  const portal = usePortal()
  return portal === 'SpyGlass' ? <SpyGlassDashboard /> : <SpyCareDashboard />
}

export default Dashboard
