import React, { useState, useEffect } from 'react';
import DashboardNav from "../components/DashboardNav";
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import IBAChartBeforeAudit from '../components/IBAChartBeforeAudit.js';
import IBAChartAfterAudit from '../components/IBAChartAfterAudit.js';
import IBAChartVolume from '../components/IBAChartVolume.js';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap/';
import { useAccess } from '../components/AccessProvider';
import { useJob } from '../components/JobProvider';
import { useAuth } from '../components/AuthProvider';
import { formatAsCurrency, formatNumberWithCommas } from '../utils';

const IBAReport = () => {
  const { ibaAccess } = useAccess();
  const [ industries, setIndustries ] = useState([]);
  const [ reportCardSpendBrackets, setReportCardSpendBrackets ] = useState([]);
  const [ clientName, setClientName ] = useState(null);
  const [ industryName, setIndustryName ] = useState(null);
  // const [defaultIndustryName, setDefaultIndustryName] = useState(null);
  const [ reportCardSpendBracketId, setReportCardSpendBracketId ] = useState(null);
  // const [defaultReportCardSpendBracketId, setDefaultReportCardSpendBracketId] = useState(null);
  const [ totalJobCountAll, setTotalJobCountAll ] = useState(null);
  const [ benchmarkSample, setBenchmarkSample ] = useState(null);
  const [ preAuditAnnualSpend, setPreAuditAnnualSpend ] = useState(null);
  const [ savingsIdentified, setSavingsIdentified ] = useState(null);
  const [ preAuditSpendFlagged, setPreAuditSpendFlagged ] = useState(null);
  const [ preAuditScore, setPreAuditScore ] = useState(null);
  const [ savingsImplemented, setSavingsImplemented ] = useState(null);
  const [ postAuditSpend, setPostAuditSpend ] = useState(null);
  const [ postAuditImplemented, setPostAuditImplemented ] = useState(null);
  const [ postAuditScore, setPostAuditScore ] = useState(null);
  const [ recCount, setRecCount ] = useState(null);
  const [ implementedRecCount, setImplementedRecCount ] = useState(null);
  const [ implementationScore, setImplementationScore ] = useState(null);
  const [ preAuditBenchmark, setPreAuditBenchmark ] = useState(null);
  const [ postAuditBenchmark, setPostAuditBenchmark ] = useState(null);
  const [ volumeBenchmark, setVolumeBenchmark ] = useState(null);
  const [ preAuditPercentiles, setPreAuditPercentiles ] = useState([]);
  const [ postAuditPercentiles, setPostAuditPercentiles ] = useState([]);
  const [ volumePercentiles, setVolumePercentiles ] = useState([]);
  const [ loading, setLoading ] = useState(true)

  const { jobId: job } = useJob();
  const { token } = useAuth();

  const [ isContentVisible, setIsContentVisible ] = useState(false);
  const [ isVolumeVisible, setIsVolumeVisible ] = useState(false);

  const handleToggleClick = () => {
    setIsContentVisible(prevState => !prevState);
  };
  const handleVolumeClick = () => {
    setIsVolumeVisible(prevState => !prevState);
  };

  useEffect(() => {
    (async () => {
      let res = await fetch(`${process.env.REACT_APP_API_URL}/data/industry/categories`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const industries = await res.json();
      setIndustries(industries.sort((a, b) => {
        if (displayIndustry(a) < displayIndustry(b)) {
          return -1
        }
        if (displayIndustry(a) > displayIndustry(b)) {
          return 1
        }
        return 0;
      }))

      res = await fetch(`${process.env.REACT_APP_API_URL}/data/report_card_spend_bracket`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const reportCardSpendBrackets = await res.json();
      setReportCardSpendBrackets(reportCardSpendBrackets);
      resetData();
      console.log('Loading Complete')
    })();


  }, [ job, token ])

  const refreshData = (industry, reportCardSpendBracketId) => {
    setIndustryName(industry);
    setReportCardSpendBracketId(reportCardSpendBracketId);

    const params = new URLSearchParams({
      industry,
      reportCardSpendBracketId
    });

    fetch(`${process.env.REACT_APP_API_URL}/data/report_card/detail/job/${job}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(reportDetail => {
        setBenchmarkSample(reportDetail.benchmarkSample);
        setPreAuditAnnualSpend(reportDetail.reportCardDetail?.totalSpend * 12);
        setSavingsIdentified(reportDetail.reportCardDetail?.totalAnnualOp);
        setPreAuditSpendFlagged(reportDetail.reportCardDetail?.totalAnnualOp / (reportDetail.reportCardDetail?.totalSpend * 12));
        setPreAuditScore(reportDetail?.reportCardDetail?.beforeScore);
        setPreAuditBenchmark(1 - (reportDetail?.aggregateTotalAnnualOp / (reportDetail?.aggregateTotalSpend * 12)))
        setSavingsImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured);
        setPostAuditSpend((reportDetail?.reportCardDetail?.totalSpend * 12) - reportDetail?.reportCardDetail?.totalAnnualCaptured)
        setPostAuditImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured / reportDetail.reportCardDetail?.totalAnnualOp);
        setPostAuditScore(reportDetail?.reportCardDetail?.afterScore);
        setRecCount(reportDetail?.reportCardDetail?.recCount);
        setImplementedRecCount(reportDetail?.reportCardDetail?.implementedRecCount);
        setImplementationScore(reportDetail?.reportCardDetail?.impPercent);
        setPostAuditBenchmark(((reportDetail?.aggregateTotalSpend * 12) - (reportDetail?.aggregateTotalAnnualOp)) / ((reportDetail?.aggregateTotalSpend * 12) - reportDetail?.aggregateTotalAnnualCaptured));
        setVolumeBenchmark(reportDetail?.aggregateImplemented / reportDetail?.aggregateTotalRecs);
        setPreAuditPercentiles(reportDetail?.preAuditPercentiles);
        setPostAuditPercentiles(reportDetail?.postAuditPercentiles);
        setVolumePercentiles(reportDetail?.volumePercentiles);
      })
  }

  const resetData = () => {
    if (job) {
      fetch(`${process.env.REACT_APP_API_URL}/data/report_card/detail/job/${job}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(reportDetail => {
          setClientName(reportDetail.reportCardDetail?.clientName);
          setIndustryName(reportDetail.reportCardDetail?.job?.industry?.category);
          // setDefaultIndustryName(reportDetail.reportCardDetail?.job?.industry?.category);
          setReportCardSpendBracketId(reportDetail?.reportCardDetail?.reportCardSpendBracket?.reportCardSpendBracketId);
          // setDefaultReportCardSpendBracketId(reportDetail?.reportCardDetail?.reportCardSpendBracket?.reportCardSpendBracketId);
          setTotalJobCountAll(reportDetail?.totalClients)
          setBenchmarkSample(reportDetail.benchmarkSample);
          setPreAuditAnnualSpend(reportDetail.reportCardDetail?.totalSpend * 12);
          setSavingsIdentified(reportDetail.reportCardDetail?.totalAnnualOp);
          setPreAuditSpendFlagged(reportDetail.reportCardDetail?.totalAnnualOp / (reportDetail.reportCardDetail?.totalSpend * 12));
          setPreAuditScore(reportDetail?.reportCardDetail?.beforeScore);
          setPreAuditBenchmark(1 - (reportDetail?.aggregateTotalAnnualOp / (reportDetail?.aggregateTotalSpend * 12)))
          setSavingsImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured);
          setPostAuditSpend((reportDetail?.reportCardDetail?.totalSpend * 12) - reportDetail?.reportCardDetail?.totalAnnualCaptured)
          setPostAuditImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured / reportDetail.reportCardDetail?.totalAnnualOp);
          setPostAuditScore(reportDetail?.reportCardDetail?.afterScore);
          setRecCount(reportDetail?.reportCardDetail?.recCount);
          setImplementedRecCount(reportDetail?.reportCardDetail?.implementedRecCount);
          setImplementationScore(reportDetail?.reportCardDetail?.impPercent);
          setPostAuditBenchmark(((reportDetail?.aggregateTotalSpend * 12) - (reportDetail?.aggregateTotalAnnualOp)) / ((reportDetail?.aggregateTotalSpend * 12) - reportDetail?.aggregateTotalAnnualCaptured));
          setVolumeBenchmark(reportDetail?.aggregateImplemented / reportDetail?.aggregateTotalRecs);
          setPreAuditPercentiles(reportDetail?.preAuditPercentiles);
          setPostAuditPercentiles(reportDetail?.postAuditPercentiles);
          setVolumePercentiles(reportDetail?.volumePercentiles);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  const displayIndustry = (i) => {
    if (i === 'Behavioral Health - Social Services') {
      return 'Behavioral Health'
    } else if (i === 'Public Sector – City and County Government') {
      return 'City and County Government'
    } else if (i === 'Public Sector – School Systems') {
      return 'School Systems'
    } else {
      return i
    }
  }
  return (
    <>
      <DashboardNav />
      {ibaAccess && !loading ? (
        <>
          <div className="position-relative">
            <Container className="mt-3">
              <Col className="align-items-end d-flex flex-row justify-content-between">
                <h1 style={{ marginBottom: '-25px' }}>Industry Benchmark Analysis</h1>
              </Col>
            </Container>
            <Container className=" mb-3">
              <Col className="align-items-end d-flex flex-row justify-content-between pb-4">
                <Col className="d-flex">
                  <div className="fw-bold pe-3">{clientName}</div>
                  <div className="border-start border-2 px-3"><span className="fw-bold">SpyGlass Clients:</span> {formatNumberWithCommas(totalJobCountAll)}</div>
                  <div className="ps-0 mb-0 border-start border-2 ps-3"><span className="fw-bold">Benchmark Sample:</span> {formatNumberWithCommas(benchmarkSample)}</div>
                </Col>
                <Row className="d-flex flex-row mt-0 align-items-end">
                  <Col className="d-flex flex-column align-items-start">
                    <div className="fw-bold mx-2">Industry:</div>
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-info" className="border border-2 p-2 fw-normal" id="Industry" style={{ minWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'left' }}>
                        {industryName === 'all' ? 'All' : displayIndustry(industryName)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item key={'all'} className={`${industryName === 'all' ? ' active' : ''}`} onClick={() => refreshData('all', reportCardSpendBracketId)}>All</Dropdown.Item>
                        {
                          industries.map(industry => (
                            <Dropdown.Item className={`${industryName === industry ? ' active' : ''}`} key={industry} onClick={() => refreshData(industry, reportCardSpendBracketId)}>{displayIndustry(industry)}</Dropdown.Item>
                          ))
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col className="d-flex flex-column align-items-start">
                    <div className="mb-0 fw-bold mx-2 w-100">Monthly Spend Bracket:</div>
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-info" className="border border-2 p-2 fw-normal" id="monthlySpendBracket" style={{ minWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'left' }}>
                        {reportCardSpendBracketId === 0 ? 'All' : reportCardSpendBrackets?.find((rsb) => rsb.reportCardSpendBracketId === reportCardSpendBracketId)?.label}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className={`${reportCardSpendBracketId === 0 ? ' active' : ''}`} onClick={() => refreshData(industryName, 0)}>All</Dropdown.Item>
                        {
                          reportCardSpendBrackets.map(reportCardSpendBracket => (
                            <Dropdown.Item className={`${reportCardSpendBracketId === reportCardSpendBracket?.reportCardSpendBracketId ? ' active' : ''}`} key={reportCardSpendBracket.reportCardSpendBracketId} onClick={() => refreshData(industryName, reportCardSpendBracket.reportCardSpendBracketId)}>{reportCardSpendBracket.label}</Dropdown.Item>
                          ))
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Group>
                      <Form.Select variant="outline-info" size="sm" className="border border-2 p-2" id="monthlySpendBracket" value={reportCardSpendBracketId} onChange={(e) => refreshData(industryName, e.target.value)}>
                        <option className='font-weight-bold' value={0}>All</option>
                        {
                          reportCardSpendBrackets.map(reportCardSpendBracket => (
                            <option disabled={reportCardSpendBracket.reportCardSpendBracketId !== defaultReportCardSpendBracketId} className={ reportCardSpendBracket.reportCardSpendBracketId === defaultReportCardSpendBracketId ? "font-weight-bold" : ''} key={reportCardSpendBracket.reportCardSpendBracketId} value={reportCardSpendBracket.reportCardSpendBracketId}>{reportCardSpendBracket.label}</option>
                          ))
                        }
                      </Form.Select>
                    </Form.Group> */}
                  </Col>
                  <Col className="d-flex flex-row align-items-center pe-0">
                    <div className="text-end">
                      <Button variant="outline-primary" onClick={resetData}>Reset</Button>
                    </div>
                    <div className="text-end ms-2">
                      <Button variant="primary">Download</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Card>
                <Card.Body>
                  <Col className="d-flex flex-row justify-content-end pe-0" style={{ marginBottom: '-10px' }}>
                    <div>
                      <Button onClick={handleToggleClick} variant="outline-primary" style={{ width: '155px' }}>
                        {isContentVisible ? "Hide After Audit" : "Show After Audit"}
                      </Button>
                    </div>
                    <div className="ms-2">
                      <Button onClick={handleVolumeClick} variant="outline-primary" style={{ width: '155px' }}>
                        {isVolumeVisible ? "Hide Volume" : "Show Volume"}
                      </Button>
                    </div>
                  </Col>
                  <Row>
                    <h2 className="mt-3 mb-0 text-center" style={{ marginLeft: '-4%' }}>Budget - How efficiently are dollars being spent?</h2>
                  </Row>
                  <IBAChartBeforeAudit
                    annualSpend={preAuditAnnualSpend}
                    savingsIdentified={savingsIdentified}
                    spendFlagged={preAuditSpendFlagged}
                    score={preAuditScore}
                    benchmark={preAuditBenchmark}
                    percentiles={preAuditPercentiles}
                  />
                  {/* <div className="text-end">
                    <Button onClick={handleToggleClick} className="btn btn-primary mb-4">
                      {isContentVisible ? "Hide After Audit" : "Show After Audit"}
                    </Button>
                  </div> */}
                  <div className={`mt-3 reveal-content ${isContentVisible ? "visible" : "hidden"}`}>
                    <IBAChartAfterAudit
                      postAuditSpend={postAuditSpend}
                      savingsImplemented={savingsImplemented}
                      implemented={postAuditImplemented}
                      score={postAuditScore}
                      benchmark={postAuditBenchmark}
                      percentiles={postAuditPercentiles}
                    />
                  </div>
                  {/* <div className="text-end">
                    <Button onClick={handleVolumeClick} className="btn btn-primary mb-4">
                      {isVolumeVisible ? "Hide Volume" : "Show Volume"}
                    </Button>
                  </div> */}
                  <div className={`mt-3 reveal-content ${isVolumeVisible ? "visible" : "hidden"}`}>
                    <Row>
                      <h2 className="text-center mt-3 mb-0">Volume - How many recommendations were implemented?</h2>
                    </Row>
                    <IBAChartVolume
                      totalRecommendations={recCount}
                      implemented={implementedRecCount}
                      declined={recCount - implementedRecCount}
                      score={implementationScore}
                      benchmark={volumeBenchmark}
                      percentiles={volumePercentiles}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </>
      ) : <>
        <div style={{ height: '300px' }} className="position-relative">
          <Container className="h-100 mt-3 text-center d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Container>
        </div>
      </>}

      <style jsx>{`
        .reveal-content {
          display: none;
          opacity: 0;
          transition: opacity 0.5s ease;
        }

        .reveal-content.visible {
          display: block;
          opacity: 1;
        }

        .reveal-content.hidden {
          display: none;
        }
      `}</style>
    </>
  );
}

export default IBAReport;
