import React, { useContext, createContext } from 'react';
import { useEffect } from 'react';
import { useJob } from '../components/JobProvider';
import { useAuth } from '../components/AuthProvider';

const AccessContext = createContext()
export const useAccess = () => useContext(AccessContext)

const AccessProvider = ({ children, value = null, setRecAccess, setInvAccess, setIbaAccess }) => {
  const { jobId: job } = useJob()
  const { token, user } = useAuth()

  useEffect(() => {
    if (user.authorities.includes('ROLE_Employee')) {
      setRecAccess(1)
      setInvAccess(1)
      setIbaAccess(1)
    }
    else if (job) {
      fetch(`${process.env.REACT_APP_API_URL}/data/job/${job}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setRecAccess(data.recommendationsAccess)
          setInvAccess(data.inventoryAccess)
          setIbaAccess(data.reportEnabled)
        })
    }
  }, [job, token, setRecAccess, setInvAccess, setIbaAccess, user])
  return (
    <AccessContext.Provider value={value}>
      {children}
    </AccessContext.Provider>
  )
}

export default AccessProvider
