import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { useJob } from './JobProvider';
import  KOMMonthlySpendLegend from './KOMMonthlySpendLegend';

// Documentation: https://www.npmjs.com/package/react-chartjs-2
// https://www.chartjs.org/docs/latest/samples/legend/html.html
// https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/Doughnut.js

// spkellydev: https://gist.github.com/spkellydev/f52eb82987104e9307e654e252182fe7
// https://www.youtube.com/watch?v=t-6Zgr5ehuM

const DoughnutChart = ({sumCallback, spendData}) => {

  const [chartData, setChartData] = useState({});
  const [legendData, setLegendData] = useState({});
  const [countData, setCountData] = useState([]);

  const doughnutContainer = {
    height: "200px",
    width: "200px"
  };

  //const counts = [16, 33, 50, 96, 22, 55];
  const data = {
    labels: ['Windstream', 'Verizon Wireless', 'AT&T Wireless', 'AT&T', 'Granite', 'Comcast'],
    datasets: [
      {
        label: 'Monthly Spend',
        data: [30103.95, 17993.54, 14039.09, 6742.36, 2210.42, 130.66],
        backgroundColor: [
          '#00A9EC',
          '#F57C00',
          '#5E35B1',
          '#2BBD7E',
          '#FF616F',
          '#C7C7C7',
          '#65DAFF',
          '#FFAD42',
          '#9162E4',
          '#9FFFE0',
          '#FF616F',
          '#8EACBB',
          '#007AB9',
          '#BB4D00',
          '#280680',
          '#69F0AE',
          '#C4001D',
          '#4F5B62',
          '#D0EBFD',
          '#EAE0FC',
          '#EAF0F6'
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      }
    }
  };
  useEffect(() => {
      if (spendData) {
          setCountData(spendData.map((d) => d.count));
          setChartData({
            labels: spendData.map((d) => d.provider),
            datasets: [
              {
              label: "Monthly Spend",
              data: spendData.map((d) => d.amount),
              backgroundColor: [
                '#00A9EC',
                '#F57C00',
                '#5E35B1',
                '#2BBD7E',
                '#FF616F',
                '#C7C7C7',
                '#65DAFF',
                '#FFAD42',
                '#9162E4',
                '#9FFFE0',
                '#FF616F',
                '#8EACBB',
                '#007AB9',
                '#BB4D00',
                '#280680',
                '#69F0AE',
                '#C4001D',
                '#4F5B62',
                '#D0EBFD',
                '#EAE0FC',
                '#EAF0F6'
              ],
              borderWidth: 0,
            },
          ],
          });
      }
  }, [spendData]);

  return (
    <>
    {chartData && Object.keys(chartData).length
      ?
        <div className="mt-2 ms-auto me-auto" style={doughnutContainer}>
          <Doughnut
            data={chartData}
            options={options}
            plugins={[{
              afterUpdate(chart, args, options) {
                if (chart.data.datasets[0]) {
                  setLegendData(chart);
                }
              }
            }]}
          />
          </div>
        : null
      }
      { legendData && Object.keys(legendData).length
        ?
          <KOMMonthlySpendLegend chartData={legendData} sumCallback={sumCallback} countData={countData}/>
        : null
      }
    </>
  )
}

export default DoughnutChart;