import styles from './DataTable.module.scss'
const DataTable = ({columns, data, keyProp}) => {
    return (
        <table>
            <thead>
                <tr className={styles.header}>
                  {columns.map((column) => (
                      <th key={column.prop}>{column.label}</th>
                  ))}
                </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row[keyProp]} className={styles.dataRow}>
                  {columns.map((column) => (
                      <td key={column.prop}>{column.prop === 'viewFileButton' ? row[column.prop]() : row[column.prop]}</td>
                  ))}
                </tr>
                ))}
            </tbody>
        </table>
    );
}

export default DataTable;