import { useState, useEffect } from 'react';

const useLocalStorage = (key, defaultValue = null) => {
  const storageValue = localStorage.getItem(key) || null
  const [value, setValue] = useState(JSON.parse(storageValue) || defaultValue)

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export default useLocalStorage