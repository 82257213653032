import React, { createContext, useContext, useEffect, useState } from "react";
import Login from '../pages/Login';
import Upload from '../pages/Upload';

const AuthContext = createContext()
export const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children, setJobId, setSpycareJobId }) => {
  const [principal, setPrincipal] = useState({ token: process.env.NODE_ENV === 'development' ? '5dc2161a-b9e3-49fe-809a-d8d01c831fff' : null, user: null })
  const [loadingAuth, setLoadingAuth] = useState(true)

  const logout = async () => {
    // await fetch(`${process.env.REACT_APP_API_URL}/uaa/logout`, {
    //   method: 'GET',
    //   headers: {
    //     Authorization: `Bearer ${principal.token}`
    //   }
    // })

    setPrincipal({})
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = process.env.REACT_APP_REDIRECT_URL
  }

  const setPrincipalFromToken = async (token) => {
    const principalData = { token }

    const userResp = await fetch(`${process.env.REACT_APP_API_URL}/data/user/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (userResp.status === 200) {
      const userObj = await userResp.json()
      principalData.user = userObj
      principalData.logout = logout
      localStorage.setItem('token', token)
      setPrincipal(principalData)
      setJobId(userObj.jobId ? userObj.jobId.toString() : null)
      if (userObj.jobId) {
        const spycareJobResp = await fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/snapshot/${userObj.jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        const spycareJobData = await spycareJobResp.text()
        const spycareJob = spycareJobData ? JSON.parse(spycareJobData) : null
        setSpycareJobId(spycareJob ? spycareJob.spycareJobId.toString() : null)
      }
    } else {
      setPrincipal({})
      logout()
    }
  }

  useEffect(() => {
    const setPrincipalData = async (token) => {
      await setPrincipalFromToken(token)
      setLoadingAuth(false)
    }
    const hash = window.location.hash
    let state = hash.match(/state=[0-9|a-z|A-Z|.|_|~|-]+/)
    let token = hash.match(/#access_token=[a-z|0-9|-]+/)
    if (state && token) {
      state = state[0].split('=')[1]
      token = token[0].split('=')[1]
      if (state === sessionStorage.getItem('state')) {
        setPrincipalData(token)
      } else {
        logout()
      }
    } else if (localStorage.getItem('token') && !principal.token) {
      setPrincipalData(localStorage.getItem('token'))
    } else if (process.env.NODE_ENV === 'development') {
      setPrincipalData(principal.token)
    } else {
      setLoadingAuth(false)
    }
  }, [])

  const renderApp = () => {
    if (loadingAuth) { return <div>Loading...</div> }
    if (window.location.pathname === '/upload/') {return <Upload />}
    if (process.env.NODE_ENV === 'development') { return children }
    if (!principal.token) { return <Login /> }
    return children
  }

  return (
    <AuthContext.Provider value={{ ...principal, logout }}>
      {renderApp()}
    </AuthContext.Provider>
  )
}

export default AuthProvider
