import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalMaterialsWelcome = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}
      dialogClassName="modal-w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modalInner">
          <Modal.Body>
            <div className="text-center">
              {
                props.companyName !== null
                ?
                  <h1>Welcome {props.companyName}!</h1>
                :
                  <h1>Welcome!</h1>
              }
              <img className="mb-3" src="/logo/SpyGlass.svg" alt="Welcome" width="280" />
            </div>
          </Modal.Body>
          <Button className="d-block mx-auto" variant="secondary" size="lg" onClick={handleClose}>Let's Get Started</Button>
        </div>
        <Modal.Footer>&nbsp;</Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalMaterialsWelcome
