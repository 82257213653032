import React, { useState, useEffect } from "react";
import { useJob } from '../components/JobProvider';
import styles from './FileManager.module.scss'
import DocumentManager from "../components/DocumentManager";
import DashboardNav from '../components/DashboardNav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FileUploadButton from "../components/FileUploadButton";
import { formatDocs, formatSpycareDocs } from "../utils";
import { usePortal } from '../components/PortalProvider';

export default function FileManager() {
    const [fetchDocs, setFetchDocs] = useState([]);

    const token = '5dc2161a-b9e3-49fe-809a-d8d01c831fff';

    const portal = usePortal();

    const { jobId: job, spycareJobId: spyCareJob } = useJob()

    useEffect(() => {
        const activeJob = portal === 'SpyGlass' ? job : spyCareJob;
        if (activeJob) {
            fetch(`${process.env.REACT_APP_API_URL}/data/${portal === 'SpyGlass' ? 'document/job/' : 'spycare/document/job/'}${activeJob}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(
                (docs) => {
                    portal === 'SpyGlass'
                        ?
                            setFetchDocs(formatDocs(docs))
                        :
                            setFetchDocs(formatSpycareDocs(docs));
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    }, [job, portal, spyCareJob]);

    const fileUploadButtonCallback = (files) => {
        const activeJob = portal === 'SpyGlass' ? job : spyCareJob;
        const formData = new FormData();
		formData.append("jobId", activeJob);
		for(let i = 0; i < files.length; i++){
			formData.append("files[" + i + "]", files[i]);
			formData.append("documentNames[" + i + "]", files[i].name);
		}

        fetch(`${process.env.REACT_APP_API_URL}/data/${portal === 'SpyGlass' ? 'multipart_document' : 'spycare/multipart_document'}`, {
            body: formData,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => res.json())
        .then(docs => {
            portal === 'SpyGlass'
            ?
                setFetchDocs(prevState => [...prevState, ...formatDocs(docs)])
            :
                setFetchDocs(prevState => [...prevState, ...formatSpycareDocs(docs)]);

        },
        (error) => {
            console.log(error);
        });
    }

    return (
        <>
            <DashboardNav/>
            <Container className="mt-3 mb-3">
                <Row>
                    <Col xs={12} className="d-flex justify-content-between align-content-center">
                        <h1 className="mb-0">Materials File Manager</h1>
                        <div className="text-end">
                            <FileUploadButton callback={fileUploadButtonCallback}/>
                        </div>
                    </Col>
                </Row>
                <div className="mt-3">
                    { fetchDocs !== undefined && fetchDocs.length !== 0
                        ?
                            <DocumentManager docs={fetchDocs}/>
                        :
                            <div className={styles.noDocs}><h3>There are no documents available to view</h3></div>
                    }
                </div>
            </Container>
        </>
    )
}