import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardNav from '../components/DashboardNav';
import DoughnutChart from '../components/komMonthlySpendDoughnut';
import styles from './KickoffMeeting.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FileUploadButton from '../components/FileUploadButton';
import { useEffect } from 'react';
import { useJob } from '../components/JobProvider';
import SanitizedHTML from 'react-sanitized-html';
import moment from 'moment';
import { useAccess } from '../components/AccessProvider';
import { Link } from 'react-router-dom';

export default function KickoffMeeting() {
  const token = '5dc2161a-b9e3-49fe-809a-d8d01c831fff';

  const [totalSpend, setTotalSpend] = useState(0);

  const [materialsUploaded, setMaterialsUploaded] = useState('--');
  const [sofDate, setSofDate] = useState(null);
  const [komNotes, setKomNotes] = useState(null);
  const [ibaReport, setIbaReport] = useState({});
  const [meetingLink, setMeetingLink] = useState(null);
  const [monthlySpendData, setMonthlySpendData] = useState({});
  const { ibaAccess } = useAccess()

  const onSumChange = (sum) => {
    setTotalSpend(sum);
  }

  const { jobId: job } = useJob();

  const fileUploadButtonCallback = (files) => {
    if (job) {
      const formData = new FormData();
      formData.append("jobId", job);
      for (let i = 0; i < files.length; i++) {
        formData.append("files[" + i + "]", files[i]);
        formData.append("documentNames[" + i + "]", files[i].name);
      }

      fetch(`${process.env.REACT_APP_API_URL}/data/multipart_document`, {
        body: formData,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => res.json())
        .then(docs => {
        },
          (error) => {
            console.log(error);
          });
    }
  }

  useEffect(() => {
    if (job) {
      fetch(`${process.env.REACT_APP_API_URL}/data/job/${job}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(job => {
          const materials = job.documents
            .filter(doc => doc.documentTypeId > 1 && doc.documentTypeId <= 7).length;
          setMaterialsUploaded(materials);
          if (job.dateSofTarget !== null) {
            const formattedSofDate = moment(job.dateSofTarget).format("MM/DD/YYYY")
            setSofDate(formattedSofDate);
          }
          setKomNotes(job.komNotes);

          fetch(`${process.env.REACT_APP_API_URL}/data/report_card/industry/${job.industry.category}/state/${job.state}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
            .then(res => res.json())
            .then(report => {
              report.totalJobCountByIndustryAndState = report.jobCountByIndustryAndState + report.historicalJobCountByIndustryAndState;
              report.totalJobCountByIndustry = report.jobCountByIndustry + report.historicalJobCountByIndustry;
              report.totalJobCountAll = report.jobCountAll + report.historicalJobCountAll;
              setIbaReport(report);
            })
        });
      fetch(`${process.env.REACT_APP_API_URL}/data/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(user => {

          setMeetingLink(user.meetingLink);
        })
      fetch(`${process.env.REACT_APP_API_URL}/data/cp_kom_spend/job/${job}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(spendData => {
          setMonthlySpendData(spendData)
        })
    }
  }, [job]);

  return (
    <>
      <DashboardNav />
      <Container className="mt-3">
        <Row>
          <Col xs={12} className="d-flex justify-content-between align-content-center">
            <h1 className="mb-0">Kick-Off Meeting</h1>
            <div className="text-end">
              <FileUploadButton callback={fileUploadButtonCallback} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3 mb-3">
        <Row className="tile">
          <Col xs={12} lg={4}>
            <Card className="card-h-66">
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Monthly Spend</Card.Title>
                  <div className="cardTotal h5">${totalSpend}</div>
                </div>
                {monthlySpendData && Object.keys(monthlySpendData).length !== 0
                  ?
                  <DoughnutChart spendData={monthlySpendData} sumCallback={onSumChange} />
                  : "No monthly spend data"
                }
              </Card.Body>
              {/* <Card.Footer><a href="/" className="card-link">View X</a></Card.Footer> */}
            </Card>
            <Card className="card-h-33">
              <Card.Body>
                <div className="text-center">
                  <div className={styles.checkSquareContainer}>
                    <FontAwesomeIcon icon={["far", "check-square"]} />
                  </div>
                  <Card.Title><span className={styles.materialsUploadedNumber}>{materialsUploaded}</span></Card.Title>
                  <Card.Text>
                    <span className={styles.materialsUploadedText}>Materials Uploaded Successfully</span>
                  </Card.Text>
                </div>
              </Card.Body>
              {/* <Card.Footer><a href="/" className="card-link">View X</a></Card.Footer> */}
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card className={`${styles.responsiveMt3__md} card-h-100`}>
              <Card.Header>
                <div className="cardTitleContainer">
                  <Card.Title className="mb-0">Intake / Production Insights</Card.Title>
                </div>
              </Card.Header>
              <Card.Body>
                <Card.Text className="richText">
                  <SanitizedHTML
                    className={styles.komNotes}
                    allowedAttributes={{ '*': ['style'] }}
                    allowedStyles={{
                      '*': {
                        'padding-left': [/^\d+(?:px|em|%)$/],
                        'padding-right': [/^\d+(?:px|em|%)$/],
                        'padding-top': [/^\d+(?:px|em|%)$/],
                        'padding-bottom': [/^\d+(?:px|em|%)$/],
                        'font-size': [/^\d+(?:px|em|%)$/]
                      }
                    }}
                    html={komNotes}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card className={`${styles.responsiveMt3__md} card-h-66`}>
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Benchmarking</Card.Title>
                </div>
                <div className={`${styles.statsContainerDashboard} statsContainer hasFooter ps-4 pe-4`}>
                  <div>
                    <h3>Sample Size</h3>
                    <div className="d-flex justify-content-between ps-3 pe-3 mt-3 flex-column flex-lg-row">
                      <div className="">
                        <h3 className="color-primary-dark">{ibaReport.totalJobCountByIndustryAndState || '--'}</h3>
                        <h6 className="font-weight-normal">Industry Size</h6>
                      </div>
                      <div className="">
                        <h3 className="color-primary-dark">{ibaReport.totalJobCountByIndustry || '--'}</h3>
                        <h6 className="font-weight-normal">Industry All</h6>
                      </div>
                      <div className="">
                        <h3 className="color-primary-dark">{ibaReport.totalJobCountAll || '--'}</h3>
                        <h6 className="font-weight-normal">All Clients</h6>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3>What You'll Be Benchmarked Against</h3>
                    <div className="d-flex justify-content-around ps-3 pe-3 mt-3 flex-column flex-lg-row">
                      <div className="">
                        <h3 className="inline-circle-number color-primary-dark">1</h3>
                        <h6 className="font-weight-normal">Pricing Analysis</h6>
                      </div>
                      <div className="">
                        <h3 className="inline-circle-number color-primary-dark">2</h3>
                        <h6 className="font-weight-normal">Opportunity Captured</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className={!ibaAccess ? styles.needSpycare : null}><Link to="/report" className="card-link">Industry Benchmarking</Link></Card.Footer>
            </Card>
            <Card className="card-h-33">
              <Card.Body>
                <div className="cardTitleContainer">
                  <Card.Title>Summary of Findings Meeting</Card.Title>
                </div>
                <Card.Text>
                  <div className={`${styles.statsContainerDashboard} statsContainer hasFooter ps-4 pe-4`}>
                    <div className={`${styles.meetingContainerKOM} d-flex align-items-center justify-content-center`}>
                      {/* Date is a Placeholder still need to populate that. I believe it's +45 days from date of KOM*/}
                      <div className={styles.meetingCalendar}><FontAwesomeIcon icon={["far", "calendar-alt"]} /></div>
                      {sofDate != null
                        ?
                        <h3 className="font-weight-normal mb-0">Target Meeting Date is <strong>{sofDate}</strong></h3>
                        :
                        <h3 className="font-weight-normal mb-0">Target Meeting Date is Not Scheduled</h3>
                      }
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
              {meetingLink !== null
                ?
                <Card.Footer>
                  <a target="_blank" rel="noopener noreferrer" href={meetingLink} className="card-link">Schedule Meeting</a>
                </Card.Footer>
                : null
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}