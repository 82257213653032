import React, { useState, useEffect, useCallback } from 'react';
import DashboardNav from "../components/DashboardNav";
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import IBAChartBeforeAuditPDF from '../components/IBAChartBeforeAuditPDF.js';
import IBAChartAfterAuditPDF from '../components/IBAChartAfterAuditPDF.js';
import IBAChartVolumePDF from '../components/IBAChartVolumePDF.js';
import IBAAfterAuditPateroChart from '../components/IBAAfterAuditPateroChart.js';
import IBABeforeAuditPateroChart from '../components/IBABeforeAuditPateroChart.js';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap/';
import { useAccess } from '../components/AccessProvider';
import { useJob } from '../components/JobProvider';
import { useAuth } from '../components/AuthProvider';
import { formatAsCurrency } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IBAReportPDF = ({ setShowNav }) => {
  setShowNav(false);
  const { ibaAccess } = useAccess();
  const [ industries, setIndustries ] = useState([]);
  const [ reportCardSpendBrackets, setReportCardSpendBrackets ] = useState([]);
  const [ clientName, setClientName ] = useState(null);
  const [ industryName, setIndustryName ] = useState(null);
  // const [defaultIndustryName, setDefaultIndustryName] = useState(null);
  const [ reportCardSpendBracketId, setReportCardSpendBracketId ] = useState(null);
  // const [defaultReportCardSpendBracketId, setDefaultReportCardSpendBracketId] = useState(null);
  const [ totalJobCountAll, setTotalJobCountAll ] = useState(null);
  const [ benchmarkSample, setBenchmarkSample ] = useState(null);
  const [ preAuditAnnualSpend, setPreAuditAnnualSpend ] = useState(null);
  const [ savingsIdentified, setSavingsIdentified ] = useState(null);
  const [ preAuditSpendFlagged, setPreAuditSpendFlagged ] = useState(null);
  const [ preAuditScore, setPreAuditScore ] = useState(null);
  const [ savingsImplemented, setSavingsImplemented ] = useState(null);
  const [ postAuditSpend, setPostAuditSpend ] = useState(null);
  const [ postAuditImplemented, setPostAuditImplemented ] = useState(null);
  const [ postAuditScore, setPostAuditScore ] = useState(null);
  const [ recCount, setRecCount ] = useState(null);
  const [ implementedRecCount, setImplementedRecCount ] = useState(null);
  const [ implementationScore, setImplementationScore ] = useState(null);
  const [ preAuditBenchmark, setPreAuditBenchmark ] = useState(null);
  const [ postAuditBenchmark, setPostAuditBenchmark ] = useState(null);
  const [ volumeBenchmark, setVolumeBenchmark ] = useState(null);
  const [ preAuditPercentiles, setPreAuditPercentiles ] = useState([]);
  const [ postAuditPercentiles, setPostAuditPercentiles ] = useState([]);
  const [ volumePercentiles, setVolumePercentiles ] = useState([]);
  const [ loading, setLoading ] = useState(true)

  const { jobId: job } = useJob();
  const { token } = useAuth();

  const [ isContentVisible, setIsContentVisible ] = useState(false);
  const [ isVolumeVisible, setIsVolumeVisible ] = useState(false);

  const handleToggleClick = () => {
    setIsContentVisible(prevState => !prevState);
  };
  const handleVolumeClick = () => {
    setIsVolumeVisible(prevState => !prevState);
  };

  useEffect(() => {
    (async () => {
      let res = await fetch(`${process.env.REACT_APP_API_URL}/data/industry/categories`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const industries = await res.json();
      setIndustries(industries.sort((a, b) => {
        if (displayIndustry(a) < displayIndustry(b)) {
          return -1
        }
        if (displayIndustry(a) > displayIndustry(b)) {
          return 1
        }
        return 0;
      }))

      res = await fetch(`${process.env.REACT_APP_API_URL}/data/report_card_spend_bracket`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const reportCardSpendBrackets = await res.json();
      setReportCardSpendBrackets(reportCardSpendBrackets);
      resetData();
      console.log('Loading Complete')
    })();


  }, [ job, token ])

  const refreshData = (industry, reportCardSpendBracketId) => {
    setIndustryName(industry);
    setReportCardSpendBracketId(reportCardSpendBracketId);

    const params = new URLSearchParams({
      industry,
      reportCardSpendBracketId
    });

    fetch(`${process.env.REACT_APP_API_URL}/data/report_card/detail/job/${job}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(reportDetail => {
        setBenchmarkSample(reportDetail.benchmarkSample);
        setPreAuditAnnualSpend(reportDetail.reportCardDetail?.totalSpend * 12);
        setSavingsIdentified(reportDetail.reportCardDetail?.totalAnnualOp);
        setPreAuditSpendFlagged(reportDetail.reportCardDetail?.totalAnnualOp / (reportDetail.reportCardDetail?.totalSpend * 12));
        setPreAuditScore(reportDetail?.reportCardDetail?.beforeScore);
        setPreAuditBenchmark(1 - (reportDetail?.aggregateTotalAnnualOp / (reportDetail?.aggregateTotalSpend * 12)))
        setSavingsImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured);
        setPostAuditSpend((reportDetail?.reportCardDetail?.totalSpend * 12) - reportDetail?.reportCardDetail?.totalAnnualCaptured)
        setPostAuditImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured / reportDetail.reportCardDetail?.totalAnnualOp);
        setPostAuditScore(reportDetail?.reportCardDetail?.afterScore);
        setRecCount(reportDetail?.reportCardDetail?.recCount);
        setImplementedRecCount(reportDetail?.reportCardDetail?.implementedRecCount);
        setImplementationScore(reportDetail?.reportCardDetail?.impPercent);
        setPostAuditBenchmark(((reportDetail?.aggregateTotalSpend * 12) - (reportDetail?.aggregateTotalAnnualOp)) / ((reportDetail?.aggregateTotalSpend * 12) - reportDetail?.aggregateTotalAnnualCaptured));
        setVolumeBenchmark(reportDetail?.aggregateImplemented / reportDetail?.aggregateTotalRecs);
        setPreAuditPercentiles(reportDetail?.preAuditPercentiles);
        setPostAuditPercentiles(reportDetail?.postAuditPercentiles);
        setVolumePercentiles(reportDetail?.volumePercentiles);
      })
  }

  const resetData = () => {
    if (job) {
      fetch(`${process.env.REACT_APP_API_URL}/data/report_card/detail/job/${job}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(reportDetail => {
          setClientName(reportDetail.reportCardDetail?.clientName);
          setIndustryName(reportDetail.reportCardDetail?.job?.industry?.category);
          // setDefaultIndustryName(reportDetail.reportCardDetail?.job?.industry?.category);
          setReportCardSpendBracketId(reportDetail?.reportCardDetail?.reportCardSpendBracket?.reportCardSpendBracketId);
          // setDefaultReportCardSpendBracketId(reportDetail?.reportCardDetail?.reportCardSpendBracket?.reportCardSpendBracketId);
          setTotalJobCountAll(reportDetail?.totalClients)
          setBenchmarkSample(reportDetail.benchmarkSample);
          setPreAuditAnnualSpend(reportDetail.reportCardDetail?.totalSpend * 12);
          setSavingsIdentified(reportDetail.reportCardDetail?.totalAnnualOp);
          setPreAuditSpendFlagged(reportDetail.reportCardDetail?.totalAnnualOp / (reportDetail.reportCardDetail?.totalSpend * 12));
          setPreAuditScore(reportDetail?.reportCardDetail?.beforeScore);
          setPreAuditBenchmark(1 - (reportDetail?.aggregateTotalAnnualOp / (reportDetail?.aggregateTotalSpend * 12)))
          setSavingsImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured);
          setPostAuditSpend((reportDetail?.reportCardDetail?.totalSpend * 12) - reportDetail?.reportCardDetail?.totalAnnualCaptured)
          setPostAuditImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured / reportDetail.reportCardDetail?.totalAnnualOp);
          setPostAuditScore(reportDetail?.reportCardDetail?.afterScore);
          setRecCount(reportDetail?.reportCardDetail?.recCount);
          setImplementedRecCount(reportDetail?.reportCardDetail?.implementedRecCount);
          setImplementationScore(reportDetail?.reportCardDetail?.impPercent);
          setPostAuditBenchmark(((reportDetail?.aggregateTotalSpend * 12) - (reportDetail?.aggregateTotalAnnualOp)) / ((reportDetail?.aggregateTotalSpend * 12) - reportDetail?.aggregateTotalAnnualCaptured));
          setVolumeBenchmark(reportDetail?.aggregateImplemented / reportDetail?.aggregateTotalRecs);
          setPreAuditPercentiles(reportDetail?.preAuditPercentiles);
          setPostAuditPercentiles(reportDetail?.postAuditPercentiles);
          setVolumePercentiles(reportDetail?.volumePercentiles);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  const displayIndustry = (i) => {
    if (i === 'Behavioral Health - Social Services') {
      return 'Behavioral Health'
    } else if (i === 'Public Sector – City and County Government') {
      return 'City and County Government'
    } else if (i === 'Public Sector – School Systems') {
      return 'School Systems'
    } else {
      return i
    }
  }
  return (
    <>
      {ibaAccess && !loading ? (
        <>
          <Container className="position-relative">
            <div className="mt-3">
              <Col className="mb-3" style={{ width: '150px' }}>
                <img src="\logo\SpyGlass.svg" />
              </Col>
              <Col className="mb-0">
                <h1>Industry Benchmark Analysis</h1>
                <h2>Prepared for {clientName}</h2>
              </Col>
            </div>
            <div className="mb-3">
              <Col className="align-items-end d-flex flex-row justify-content-between pb-4">
                <Col className="d-flex">
                  <div className="pe-3"><span className="fw-bold">SpyGlass Clients:</span> {totalJobCountAll}</div>
                  <div className="mb-0 border-start border-2 px-3"><span className="fw-bold">Benchmark Sample:</span> {benchmarkSample}</div>
                  <div className="mb-0 border-start border-2 px-3"><span className="fw-bold">Industry:</span> {industryName}</div>
                  <div className="mb-0 border-start border-2 px-3"><span className="fw-bold">Monthly Spend Bracket:</span> {reportCardSpendBracketId}</div>
                </Col>
              </Col>
              <Row>
                <h2 className="mt-3 mb-4 text-center" >Budget - How efficiently are dollars being spent?</h2>
              </Row>

              <IBAChartBeforeAuditPDF
                annualSpend={preAuditAnnualSpend}
                savingsIdentified={savingsIdentified}
                spendFlagged={preAuditSpendFlagged}
                score={preAuditScore}
                benchmark={preAuditBenchmark}
                percentiles={preAuditPercentiles}
              />


              <IBAChartAfterAuditPDF
                postAuditSpend={postAuditSpend}
                savingsImplemented={savingsImplemented}
                implemented={postAuditImplemented}
                score={postAuditScore}
                benchmark={postAuditBenchmark}
                percentiles={postAuditPercentiles}
              />

              <Row>
                <h2 className="mt-5 mb-4 text-center">Volume - How many recommendations were implemented?</h2>
              </Row>

              <IBAChartVolumePDF
                totalRecommendations={recCount}
                implemented={implementedRecCount}
                declined={recCount - implementedRecCount}
                score={implementationScore}
                benchmark={volumeBenchmark}
                percentiles={volumePercentiles}
              />

            </div>
            <div className="py-3 my-3">
              <Row>
                <Col className="col-4 text-center">
                  <h4>Corporate Address</h4>
                  <div>25777 Detroit Road, Suite 400</div>
                  <div>Westlake, OH 44145</div>
                </Col>
                <Col className="col-4 text-center">
                  <h4>Contact Us</h4>
                  <div>Toll Free: 1.877.4.SPY.NOW (779669)</div>
                  <div>Email: info@spyglass.net</div>
                </Col>
                <Col className="col-4 text-center">
                  <h4>We're on Social</h4>
                  <div className="d-flex flex-row justify-content-between align-items-center m-auto" style={{ width: '125px' }}>
                    <a href="https://www.linkedin.com/company/the-spyglass-group-llc-/" style={{ width: '25px', verticalAlign: 'middle' }}><img src="social/linkedin-brands-solid.svg" /></a>
                    <a href="https://m.facebook.com/pages/category/Business-Consultant/The-SpyGlass-Group-LLC-572802199496951/" style={{ width: '25px', verticalAlign: 'middle' }}><img src="social/facebook-brands-solid.svg" /></a>
                    <a href="https://www.instagram.com/thespyglassgroup/?hl=en" style={{ width: '25px', verticalAlign: 'middle' }}><img src="social/instagram-brands-solid.svg" /></a>
                    <a href="https://www.youtube.com/channel/UCt9RACtKLmuCsMNAbk1vR0w" style={{ width: '25px', verticalAlign: 'middle' }}><img src="social/youtube-brands-solid.svg" /></a>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </>
      ) : <>
        <div style={{ height: '300px' }} className="position-relative">
          <Container className="h-100 mt-3 text-center d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Container>
        </div>
      </>}

      <style jsx>{`
        @media print {
          html, body {
              display: inline-block;
            }
          }
      `}</style>
    </>
  );
}

export default IBAReportPDF;
