import React, { useContext, createContext } from 'react';

const JobContext = createContext()
export const useJob = () => useContext(JobContext)

const JobProvider = ({ children, value = null }) => (
  <JobContext.Provider value={value}>
    {children}
  </JobContext.Provider>
)

export default JobProvider
