import Button from 'react-bootstrap/Button';
import styles from './recRendering.module.scss';
import { ButtonGroup } from "react-bootstrap";

const formatters = {
  'formatCurrency': (params) => (
    params.value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  ),
  'formatCurrencyFromString': (params) => {
    const val = params.value?.replace('$', '');
    if (val === '0') { return '$0.00' }
    if (+val && parseFloat(val?.replace(/[^0-9.]/g, ""))) {
      return parseFloat(val?.replace(/[^0-9.]/g, "")).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }
    return val
  },
  "formatYesNo": (params) => (
    params.value ? 'Yes' : 'No'
  )
  ,
  'formatFloatingPoint': (params) => (
    params.value ? Number(params.value)?.toFixed(3) : null
  )
}

const getters = {
  'getDifference': (params) => (
    params.data ? (params.data.baseRate - params.data.proposedBaseRate).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null
  )
}

const feedbackCellClassRules = {
  // apply Cancel Button color to Feedback Column
  'color-confirmation': params => params.value === 'Cancel',

  // apply Keep Button color to Feedback Column
  'color-cancel': params => params.value === 'Keep',

  // apply Keep Button color to Feedback Column
  'color-warning-alt': params => params.value === 'Open'
}

const renderers = {
  'renderNotes': (props) => {
    const setNotesRec = props.setNotesRec
    const saveColumnState = props.saveCurrentColumnState

    return props.data ? (
      <Button
        variant="blueGreyExtraLight"
        size="sm"
        onClick={() => {
          saveColumnState()
          setNotesRec(props.data)
        }
        }
        key="notes"
      >
        Notes
      </Button>
    ) : null
  },
  'renderReasonNotes': (props) => {
    return props.data?.reasonNo ? `${props.data.reasonNo.name} - ${props.value}` : props.value || null
  },
  'renderInventoryNotes': (props) => {
    const setNotesInv = props.setNotesInv
    const saveColumnState = props.saveCurrentColumnState

    return props.data ? (
      <Button
        variant="blueGreyExtraLight"
        size="sm"
        onClick={() => {
          saveColumnState()
          setNotesInv(props.data)
        }
        }
        key="notes"
      >
        Notes
      </Button>
    ) : null
  },
  'renderUpdateFeedback': (props) => {
    const { cancelRec, keepRec, reduceRec, portRec, researchRec, undoRec, saveCurrentColumnState } = props
    if (!props.data) { return null }
    const buttons = []
    const feedbackTypeName = props.data?.feedbackType?.name
    const disableReduce = props.data?.disableReduce || false
    const proposedRatePresent = props.data.proposedBaseRate ? 1 : 0
    const { recommendationCategory: recCategory, portToPri, isdId } = props.data
    if (feedbackTypeName === 'Open' || feedbackTypeName === 'Research') {
      buttons.push(
        <Button
          variant="confirmation"
          size="sm"
          onClick={() => {
            saveCurrentColumnState()
            cancelRec(props.data)
          }}
          key="cancel"
        >
          {recCategory === 'Elimination' ? 'Cancel' : 'Implement'}
        </Button>,
        <Button
          variant="cancel"
          size="sm"
          onClick={() => {
            saveCurrentColumnState()
            keepRec(props.data)
          }}
          key="keep"
        >
          {recCategory === 'Elimination' ? 'Keep' : "Don't Implement"}
        </Button>
      )
    }
    if (recCategory === 'Elimination') {
      const elimButtons = (reduceRec === undefined || !proposedRatePresent) ? [] : [
        <Button
          variant="confirmationLight"
          size="sm"
          onClick={() => {
            saveCurrentColumnState()
            reduceRec(props.data)
          }}
          disabled={disableReduce}
          key="reduce"
        >
          Reduce
        </Button>
      ]
      if (portToPri > 0 && feedbackTypeName === 'Open') {
        elimButtons.unshift(
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              saveCurrentColumnState()
              portRec(props.data)
            }}
            key="port"
          >
            Port
          </Button>
        )
      }
      buttons.push(...elimButtons)
    } else {
      if (isdId && researchRec !== undefined) {
        buttons.push(
          <Button
            variant="primary"
            size="sm"
            disabled={feedbackTypeName === 'Research'}
            onClick={() => {
              saveCurrentColumnState()
              researchRec(props.data)
            }}
            key="research"
          >
            Research
          </Button>
        )
      }
    }
    if (!isdId && feedbackTypeName !== 'Open' && feedbackTypeName !== 'Research') {
      buttons.push(
        <Button
          variant="blueGreyDark"
          size="sm"
          onClick={() => {
            saveCurrentColumnState()
            undoRec(props.data)
          }}
          key="undo"
        >
          Undo
        </Button>
      )
    }
    return (
      <ButtonGroup className={styles.updateButtons}>
        {buttons}
      </ButtonGroup>
    )
  },
  'renderAddRecommendation': (props) => {
    const setRecModalActive = props.setRecModalActive
    const setEditRecModalActive = props.setEditRecModalActive
    const saveColumnState = props.saveCurrentColumnState
    const setCurrentInventory = props.setCurrentInventory

    return !props.data?.recommendations?.length ? (
      <Button
        variant="blueGreyExtraLight"
        size="sm"
        onClick={() => {
          saveColumnState()
          setRecModalActive(props.data)
          setCurrentInventory(props.data.inventoryId)
        }
        }
        key="addRec"
      >
        Add Recommendation
      </Button>
    ) : (
      <Button
        variant="blueGreyDark"
        size="sm"
        onClick={() => {
          saveColumnState()
          setEditRecModalActive(props.data)
          setCurrentInventory(props.data.inventoryId)
        }}
        key="editRec"
      >
        Edit Recommendation
      </Button>
    )
  },
  'renderDeleteInventory': (props) => {
    const deleteInv = props.deleteInventory
    return (
      <Button
        variant="cancel"
        size="sm"
        onClick={() => {
          deleteInv(props.value)
        }}
        key="deleteInv"
      >
        Delete
      </Button>)
  }
}

export {
  formatters,
  getters,
  renderers,
  feedbackCellClassRules
}
