const calculateSavings = (job, spyCare = 0) => {
  let totalRecCount = 0
  let implementedRecCount = 0
  let openRecCount = 0

  job.recSummaries.forEach(sum => {
    totalRecCount += sum.recommendations.length
    sum.recommendations.forEach(rec => {
      if (rec.feedbackType && (rec.feedbackType.feedbackTypeId === 2 || rec.feedbackType.feedbackTypeId === 4)) {
        implementedRecCount++
      } else if (rec.feedbackType && rec.feedbackType.feedbackTypeId === 1) {
        openRecCount++
      }
    })
  })

  const savingsPossible = sofTotal(job.recSummaries, job)
  let savingsCaptured = spyCare ? spycareAcceptedTotal(job.recSummaries, job) : billedTotal(job.recSummaries, job) + yesNotDoneTotal(job.recSummaries, job)
  const savingsDeclined = nosTotal(job.recSummaries, job)
  const openOpportunity = openTotal(job.recSummaries, job)

  return {
    totalRecCount,
    implementedRecCount,
    openRecCount,
    savingsPossible,
    savingsCaptured,
    savingsDeclined,
    openOpportunity,
  }
}

const calculateSof = (summary, job = { retroactiveTerms: 100 }, location = 'All Locations') => {
  let result = 0
  summary.recommendations.forEach(rec => {
    const locationMatch = location === 'All Locations' || `${rec.inventory?.address}, ${rec.inventory?.city}, ${rec.inventory?.state}` === location
    if (locationMatch) {
      if (summary.recommendationType.category === 'Eliminate') {
        result +=
          (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12
      } else if (summary.recommendationType.category === 'Cost Reduction') {
        result += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
          - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12
      } else if (summary.recommendationType.category === 'Credit') {
        result += (rec.retroAmount || 0) * job.retroactiveTerms / 100
      } 
    }
  })
  return result
}

const calculateOpen = (summary, job = { retroactiveTerms: 100 }, location = 'All Locations') => {
  let result = 0
  summary.recommendations.forEach((rec) => {
    const locationMatch = location === 'All Locations' || `${rec.inventory?.address}, ${rec.inventory?.city}, ${rec.inventory?.state}` === location
    if (rec.feedbackType?.feedbackTypeId === 1 && locationMatch) {
      if (summary.recommendationType.category === 'Eliminate') {
        result += (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12
      } else if (summary.recommendationType.category === 'Cost Reduction') {
        result += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
          - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12
      } else if (summary.recommendationType.category === 'Credit') {
        result += (rec.retroAmount || 0) * job.retroactiveTerms / 100
      } else if (summary.recommendationType.category === 'Adjustment') {
        result += rec.billedAmount || 0
      }
    }
  })
  return result
}

const calculateNos = (summary, job = { retroactiveTerms: 100 }, location = 'All Locations') => {
  let result = 0
  summary.recommendations.forEach((rec) => {
    const locationMatch = location === 'All Locations' || `${rec.inventory?.address}, ${rec.inventory?.city}, ${rec.inventory?.state}` === location
    if (locationMatch) {
      if (rec.feedbackType?.feedbackTypeId == 3) {
        if (summary.recommendationType.category === 'Eliminate') {
          result += (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12
        } else if (summary.recommendationType.category === 'Cost Reduction') {
          result += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
            - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12
        } else if (summary.recommendationType.category === 'Credit') {
          result += (rec.retroAmount || 0) * job.retroactiveTerms / 100
        } else if (summary.recommendationType.category === 'Adjustment') {
          result += rec.billedAmount || 0
        }
      }
    }
  })
  return result
}

const calculateYesNotDone = (summary, job = { retroactiveTerms: 100 }, location = 'All Locations') => {
  let result = 0
  summary.recommendations.forEach(rec => {
    const locationMatch = location === 'All Locations' || `${rec.inventory?.address}, ${rec.inventory?.city}, ${rec.inventory?.state}` === location
    if ((rec.feedbackType?.feedbackTypeId === 2 || rec.feedbackType?.feedbackTypeId === 4)
      && rec.isdId === null
      && !rec.recDone
      && locationMatch) {
      if (summary.recommendationType.category === 'Eliminate') {
        result += (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12
      } else if (summary.recommendationType.category === 'Cost Reduction') {
        result += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
          - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12
      } else if (summary.recommendationType.category === 'Credit') {
        result += result += (rec.retroAmount || 0) * job.retroactiveTerms / 100
      } else if (summary.recommendationType.category === 'Adjustment') {
        result += result += rec.billedAmount || 0
      }
    }
  })
  return result
}

const calculateBilled = (summary, job = { retroactiveTerms: 100 }, location = 'All Locations') => {
  let currentDate = new Date()
  let filteredRecs = summary.recommendations.filter((rec) => {
    const locationMatch = location === 'All Locations' || `${rec.inventory?.address}, ${rec.inventory?.city}, ${rec.inventory?.state}` === location
    return (rec.confirmationNumber != null || rec.doneBy != null) 
           && (rec.dateEffective != null) 
           //&& (currentDate >= new Date(rec.dateEffective + ' 00:00:00') || currentDate >= new Date(rec.createdOn)) 
           && locationMatch
  });

  let result = 0;
  filteredRecs.forEach(rec => {
    if ((rec.feedbackType?.feedbackTypeId == 2 || rec.feedbackType?.feedbackTypeId == 4)) {
      if (summary.recommendationType.category === 'Eliminate') {
        result += (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12
      } else if (summary.recommendationType.category === 'Cost Reduction') {
        result += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
          - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12
      } else if (summary.recommendationType.category === 'Credit') {
        result += (rec.retroAmount || 0) * job.retroactiveTerms / 100
      } else if (summary.recommendationType.category === 'Adjustment') {
        result += rec.billedAmount || 0
      }
    }
  })
  return result
}

const calculateReadyToBill = (summary, location = 'All Locations') => {
  let result = 0
  summary.recommendations.forEach(rec => {
    const locationMatch = location === 'All Locations' || `${rec.inventory?.address}, ${rec.inventory?.city}, ${rec.inventory?.state}` === location
    if ((rec.feedbackType?.feedbackTypeId == 2 || rec.feedbackType?.feedbackTypeId == 4)
      && rec.isdId == null
      && +rec.recDone > 0
      && locationMatch) {
      result += rec.billedAmount
    }
  })
  return result
}

const calculateSpycareAccepted = (summary, job = { retroactiveTerms: 100 }, location = 'All Locations') => {
  let result = 0
  summary.recommendations.forEach(rec => {
    const locationMatch = location === 'All Locations' || `${rec.inventory?.address}, ${rec.inventory?.city}, ${rec.inventory?.state}` === location
    if ((rec.feedbackType?.feedbackTypeId === 2 || rec.feedbackType?.feedbackTypeId === 4) && locationMatch) {
      if (summary.recommendationType.category === 'Eliminate') {
        result += (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12
      } else if (summary.recommendationType.category === 'Cost Reduction') {
        result += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
          - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12
      } else if (summary.recommendationType.category === 'Credit') {
        result += result += (rec.retroAmount || 0) * job.retroactiveTerms / 100
      } else if (summary.recommendationType.category === 'Adjustment') {
        result += result += rec.billedAmount || 0
      }
    }
  })
  return result
}

const sofTotal = (summaries, job, location = 'All Locations') => (
  summaries.reduce((total, sum) => (
    total + calculateSof(sum, job, location)
  ), 0)
)

const openTotal = (summaries, job, location = 'All Locations') => (
  summaries.reduce((total, sum) => (
    total + calculateOpen(sum, job, location)
  ), 0)
)

const nosTotal = (summaries, job, location = 'All Locations') => (
  summaries.reduce((total, sum) => (
    total + calculateNos(sum, job, location)
  ), 0)
)

const yesNotDoneTotal = (summaries, job, location = 'All Locations') => (
  summaries.reduce((total, sum) => (
    total + calculateYesNotDone(sum, job, location)
  ), 0)
)

const billedTotal = (summaries, job, location = 'All Locations') => (
  summaries.reduce((total, sum) => (
    total + calculateBilled(sum, job, location)
  ), 0)
)

const readyToBillTotal = (summaries, location = 'All Locations') => (
  summaries.reduce((total, sum) => (
    total + calculateReadyToBill(sum, location)
  ), 0)
)

const spycareAcceptedTotal = (summaries, job, location = 'All Locations') => (
  summaries.reduce((total, sum) => (
    total + calculateSpycareAccepted(sum, job, location)
  ), 0)
)

const calculateBilledThisMonth = (summary) => {
  const thisMonth = new Date().getMonth()
  const thisYear = new Date().getFullYear()
  return summary.recommendations.reduce((total, rec) => {
    if (!rec.isd) { return total }
    const isdBilledMonth = new Date(rec.isd.billedDate + 'T00:00:00').getMonth()
    const isdBilledYear = new Date(rec.isd.billedDate + 'T00:00:00').getFullYear()
    return (isdBilledMonth === thisMonth && isdBilledYear === thisYear) ? total + rec.billedAmount : total
  }, 0).toFixed(2)
}

const calculateTotalMonthlyFeedback = (summary, job) => {
  return parseFloat(calculateYesNotDone(summary, job))
    + parseFloat(calculateReadyToBill(summary))
    + parseFloat(calculateBilledThisMonth(summary))
}

// old method
// const calculateSavings = (job) => {
//   let implementedRecCount = 0
//   let openRecCount = 0
//   let totalRecCount = 0
//   let savingsCaptured = 0
//   let savingsPossible = 0
//   let savingsDeclined = 0
//   let openOpportunity = 0

//   job.recSummaries.filter(sum => ['Eliminate', 'Cost Reduction'].includes(sum.recommendationType.category)).forEach(sum => {
//     totalRecCount += sum.recommendations.length
//     sum.recommendations.forEach(rec => {
//       let recSavings = 0

//       if (rec.feedbackType && (rec.feedbackType.feedbackTypeId === 2 || rec.feedbackType.feedbackTypeId === 4)) {
//         implementedRecCount++
//       }

//       if (rec.isdId !== null && rec.feedbackType && [2, 4].includes(rec.feedbackType.feedbackTypeId)) {
//         savingsCaptured += rec.billedAmount
//       }
//       if (rec.feedbackType && rec.isdId === null && [2, 4].includes(rec.feedbackType.feedbackTypeId) && rec.recDone > 0) {
//         savingsCaptured += rec.billedAmount
//       }

//       if (sum.recommendationType.category === 'Eliminate') {
//         recSavings += (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12;

//         if (rec.feedbackType && [2, 4].includes(rec.feedbackType.feedbackTypeId) && rec.isdId === null && !rec.recDone) {
//           savingsCaptured += recSavings
//         }
//       } else if (sum.recommendationType.category === 'Cost Reduction') {
//         recSavings += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
//           - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12

//         if (rec.feedbackType && rec.feedbackType.feedbackTypeId === 2 && rec.isdId === null && !rec.recDone) {
//           savingsCaptured += recSavings
//         }
//       }
//       if (rec.feedbackType && rec.feedbackType.feedbackTypeId === 1) {
//         openOpportunity += recSavings
//         openRecCount++
//       } else if (rec.feedbackType && rec.feedbackType.feedbackTypeId === 3) {
//         savingsDeclined += recSavings
//       }

//       savingsPossible += recSavings
//     })
//   })

//   return {
//     implementedRecCount,
//     openRecCount,
//     totalRecCount,
//     savingsCaptured,
//     savingsPossible,
//     savingsDeclined,
//     openOpportunity,
//   }
// }

// old method
// const updateSavings = (job, recommendation) => {
//   let implementedRecCount = 0
//   let openRecCount = 0
//   let totalRecCount = 0
//   let savingsCaptured = 0
//   let savingsPossible = 0
//   let savingsDeclined = 0
//   let openOpportunity = 0

//   job.recSummaries.forEach(sum => {
//     totalRecCount += sum.recommendations.length
//     sum.recommendations.forEach(currentRec => {
//       const rec = currentRec.recommendationId === recommendation.recommendationId ? recommendation : currentRec
//       let recSavings = 0

//       if (rec.feedbackType && (rec.feedbackType.feedbackTypeId === 2 || rec.feedbackType.feedbackTypeId === 4)) {
//         implementedRecCount++
//       }

//       if (rec.isdId !== null && rec.feedbackType && [2, 4].includes(rec.feedbackType.feedbackTypeId)) {
//         savingsCaptured += rec.billedAmount
//       }
//       if (rec.feedbackType && rec.isdId === null && [2, 4].includes(rec.feedbackType.feedbackTypeId) && rec.recDone > 0) {
//         savingsCaptured += rec.billedAmount
//       }

//       if (sum.recommendationType.category === 'Eliminate') {
//         recSavings += (rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax) * 12;

//         if (rec.feedbackType && [2, 4].includes(rec.feedbackType.feedbackTypeId) && rec.isdId === null && !rec.recDone) {
//           savingsCaptured += recSavings
//         }
//       } else if (sum.recommendationType.category === 'Cost Reduction') {
//         recSavings += ((rec.baseRate + rec.surchargeAndFees + rec.stateTax + rec.federalTax)
//           - (rec.proposedBaseRate + rec.proposedSurchargeAndFees + rec.proposedStateTax + rec.proposedFederalTax)) * 12

//         if (rec.feedbackType && rec.feedbackType.feedbackTypeId === 2 && rec.isdId === null && !rec.recDone) {
//           savingsCaptured += recSavings
//         }
//       }
//       if (rec.feedbackType && rec.feedbackType.feedbackTypeId === 1) {
//         openOpportunity += recSavings
//         openRecCount++
//       } else if (rec.feedbackType && rec.feedbackType.feedbackTypeId === 3) {
//         savingsDeclined += recSavings
//       }

//       savingsPossible += recSavings
//     })
//   })

//   return {
//     implementedRecCount,
//     openRecCount,
//     totalRecCount,
//     savingsCaptured,
//     savingsPossible,
//     savingsDeclined,
//     openOpportunity,
//   }
// }

export {
  calculateSavings,
  calculateSof,
  calculateOpen,
  calculateNos,
  calculateYesNotDone,
  calculateBilled,
  calculateReadyToBill,
  calculateSpycareAccepted,
  sofTotal,
  openTotal,
  nosTotal,
  yesNotDoneTotal,
  billedTotal,
  readyToBillTotal,
  spycareAcceptedTotal,
  calculateTotalMonthlyFeedback,
}
